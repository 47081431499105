
const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_hotelInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/hotelInfo',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_hotelInfo;