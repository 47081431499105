import moment from 'moment'
import consumer_apply_applyJourneyList from '@/lib/data-service/haolv-default/consumer_apply_applyJourneyList'
let deferred = function () {
    let dfd = {};
    dfd.promise = new Promise((resolve, reject) => {
        dfd.resolve = resolve;
        dfd.reject = reject;
    })
    return dfd
}
export default {
    name: 'ReservePopupBox',
    data () {
        return {
            loading: false,
            businessTravelList: [],
            showPop: false,
            haveTravel: false,
            listDeferred: deferred(),
            currentItem: null,
        }
    },
    props: {
        journeyId: {
            type: [Number, String],
            default: 0,
        },
        /*currentItem: {
            type: Object,
            default: null
        },*/
        // 筛选显示到类型
        businessType: {
            type: Number,
            default: 0 // 1.机票，2.火车票，3.酒店
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created () {},
    mounted () {
        document.addEventListener('click', this.clickReserveWrapper)
        this.setResultList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        document.removeEventListener('click', this.clickReserveWrapper)
    },
    watch: {
        businessType(newVal, oldVal) {
            this.setHaveTravel()
        },
        disable(newVal) {
            if (newVal) {
                this.currentItem = null
                this.showPop = false
            }
        },
        journeyId: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal === '' || newVal === 0) {
                    this.currentItem = null
                    this.$emit('getCurrent', this.currentItem)
                    return
                }
                this.callbackReady().then(() => {
                    let currentItem = null
                    this.businessTravelList.forEach(value => {
                        value.journeys.forEach(value1 => {
                            if (value1.id === newVal) {
                                currentItem = JSON.parse(JSON.stringify(value))
                                currentItem.active = value1
                                currentItem.active.evectionId = value.id
                            }
                        })
                    })
                    this.currentItem = currentItem
                    this.$emit('getCurrent', currentItem)
                })
            }
        }
    },
    computed: {
        currentJourneyId: {
            get () {
                return this.journeyId
            },
            set (val) {
                this.$emit('update:journeyId', val)
            }
        },
    },
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日')
            return day
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        setJourneyId(params) {
            let journeyId = params.journeyId;
            return new Promise(resolve => {
                this.callbackReady().then(() => {
                    let currentItem = null
                    this.businessTravelList.forEach(value => {
                        value.journeys.forEach(value1 => {
                            if (value1.id === journeyId) {
                                currentItem = JSON.parse(JSON.stringify(value))
                                currentItem.active = value1
                                currentItem.active.evectionId = value.id
                            }
                        })
                    })
                    this.currentItem = currentItem
                    resolve(currentItem);
                })
            })
        },
        callbackReady() {
            return this.listDeferred.promise
        },
        setResultList() {
            this.loading = true
            let params = {
                pageSize: 1000,
                currentPage: 1,
                status: 2,
                approveType: 1,
            };
            consumer_apply_applyJourneyList(params).then((res) => {
                this.businessTravelList = res.datas;
                this.listDeferred.resolve()
                this.setHaveTravel()
            }).finally(() => {
                this.loading = false
            });
        },
        setHaveTravel() {
            let haveTravel = false
            if (this.businessTravelList.length === 0) {
                haveTravel = false
            }
            this.businessTravelList.forEach(value => {
                if (value.journeys) {
                    value.journeys.forEach(value1 => {
                        if (this.businessType === 0 || value1.businessType === this.businessType) {
                            haveTravel = true
                        }
                    })
                }
            })
            this.haveTravel = haveTravel
        },
        chooseTravel() {
            setTimeout(() => {
                this.showPop = true
            }, 300)
        },
        toggleShowPop() {
            if (this.disable) {
                return
            }
            this.showPop = !this.showPop
        },
        clickReserveWrapper(e) {
            if (!!this.$refs.reserveChooseBox.contains(e.target)) return
            this.showPop = false
        },
        getCurrentItem(item, journey, index, jIndex) {
            /*let current = item
            current.active = journey
            this.current = current
            this.toggleShowPop()
            this.$emit('chooseJourney', this.current)*/
            let current = item
            current.active = journey
            this.currentItem = current;
            this.currentJourneyId = journey.id
            this.$emit('getCurrent', this.currentItem)
            this.showPop = false
        },
        isShowItem(item) {
            let haveStaffList = false
            item.journeys.forEach(value => {
                if (value.staffList.length > 0) {
                    haveStaffList = true
                }
            })
            if (this.businessType === 0) {
                return haveStaffList
            } else {
                let show = false
                item.journeys.forEach(value => {
                    if (value.businessType === this.businessType) {
                        show = true
                    }
                })
                return show && haveStaffList
            }
        },
        del() {
            this.currentJourneyId = ''
            //this.currentItem = null
            //this.$emit('chooseJourney', this.currentItem)
        },
        isShowJourney(journey) {
            let isThisBusinessType = (this.businessType === 0 || journey.businessType === this.businessType)
            return isThisBusinessType
        },
        setActiveItemName(activeItem) {
            if (!activeItem) {
                return ''
            }
            let name = ''
            name += `${activeItem.evectionReason}-`
            let businessName = ''
            if (activeItem.active.businessType === 1) {
                businessName = '机票'
            } else if (activeItem.active.businessType === 2) {
                businessName = '火车票'
            } else {
                businessName = '酒店'
            }
            name += `[${businessName}] `
            if (activeItem.active.businessType === 3) {
                name += `${activeItem.active.departCityName || activeItem.active.toCityName} ${activeItem.active.tripDate}`
            } else {
                name += `${activeItem.active.departCityName}-${activeItem.active.toCityName} ${activeItem.active.tripDate}`
            }
            return name
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
