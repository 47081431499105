import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

import consumer_t_od_hotel_order_interim_searchHotelOrderList from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_searchHotelOrderList'
import consumer_t_od_hotel_order_interim_hotelOrderStatusCount from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelOrderStatusCount'
import consumer_t_od_hotel_order_interim_downloadHotelOrderListDetails from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_downloadHotelOrderListDetails'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            loading: false,
            searchForm: {
                endDate: '',
                startDate: '',
                deptIds: [], //
                deptIdList: [],
                name: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数

            activeName: '-1',
            tabList: [],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.getDeptList();
        this.getOrderList()
        this.getListNum()
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDate = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDate = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
    },
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        // 获取订单列表
        getOrderList() {
            this.loading = true
            this.searchForm.orderStatus = parseInt(this.activeName)
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_t_od_hotel_order_interim_searchHotelOrderList(this.searchForm).then(res => {
                let orderList = res.datas.list
                orderList.forEach(value => {
                    let nameList = []
                    if (value.name) {
                        nameList = value.name.split(',')
                    }
                    value.nameList = nameList
                })
                this.orderList = orderList
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = []
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            })
        },
        getListNum() {
            let {orderStatus, ...params} = this.searchForm;
            consumer_t_od_hotel_order_interim_hotelOrderStatusCount(params).then(res => {
                let tabList = res.datas.results;
                tabList.sort((a,b)=>{
                    return a.serialNum - b.serialNum;
                })
                this.tabList = tabList;
            })
        },
        // 搜索
        onSearch() {
            this.searchForm.currentPage = 1
            this.getOrderList()
            this.getListNum()
        },
        // 重置
        onReset() {
            this.searchForm = {
                endDate: '',
                startDate: '',
                deptIds: [], //
                deptIdList: [],
                name: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            }
            this.applyTime = []
            this.onSearch()
        },
        // 订单状态
        getStatusToClass(val) {
            //订单状态 0：待支付 2：待确认 6：已取消 11：已出房 14：出房失败 18：超标审批中
            if (val === 0 || val === 2 || val === 18) {
                return 'yellow-text'
            } else if (val === 11) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
        // 查看详情
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-hotel-order-detail',
                query: {
                    orderNo: val.orderNo
                }
            })
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            consumer_t_od_hotel_order_interim_downloadHotelOrderListDetails(this.searchForm).then(res => {
                let url = res.datas.orderDetailsUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
    }
}
