export default {
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            default: ()=>{},
        },
        type: {
            type: Number,
            default: 1,
        },
        detail: {
            type: Object,
            default: ()=>{},
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toDetail() {
            this.$router.push({
                name: 'admin-hotel-order-detail',
                query: {
                    orderNo: this.item.orderNo,
                }
            })
        }
    }
}
