import moment from "moment";
export default {
    data() {
        return {
            moment: moment,
        }
    },
    props: {
        newItem: {
            type: Object,
            default: ()=>{},
        },
        item: {
            type: Object,
            default: ()=>{},
        },
        type: {
            type: Number,
            default: 1,
        },
        detail: {
            type: Object,
            default: ()=>{},
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toChangeDetail() {
            this.$router.push({
                name: 'admin-my-order-train-change-detail',
                query: {
                    changeNo: this.newItem.changeOrderNo,
                    orderNo: this.item.orderNo,
                    jumpType: '',
                }
            })
        }
    }
}
