/**
 * 调用方法：
 * 初始化方法： init
 * 入参：OBJ
 * {
 *     companyId: Number 公司ID
 *     evectionNo: String 出差单号
 *     mock_data: Obj {
 *         enable: Boolean 默认false true： 使用虚拟数据 false: 不适用虚拟数据
 *         test_case: String 001: 因公有出差单 002：因公无出差单 003: 因私
 *     }
 * }
 *
 * 打开窗口: toShow
 * 入参： Obj
 * {
 *     activePassengerList:  Array(String) 当前选中的用户id数组
 * }
 *
 * 获取当前窗口打开的状态: get_show
 *
 * 关闭最上一层窗口: hide
 *
 *
 * 组件回调事件
 * @result
 * 返参：Obj
 * {
 *     userList: Array(Object) 选取的实例数组
 *     userIdList: Array(String) 选中的id数组
 * }
 * */


import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'

export default {
    data() {
        return {
          loading: true,
          switchOff: true,
          visible: false,
          fromStationCodeOld: '',
          toStationCodeOld: '',
          trainCodeOld: '',
          trainDateOld: '',
          timeTable: [],
        }
    },
    props: {
        fromStationCode: {
            type: String,
            default: '',
        },
        toStationCode: {
            type: String,
            default: '',
        },
        trainCode: {
            type: String,
            default: '',
        },
        trainDate: {
            type: String,
            default: '',
        },
    },
    components: {},
    created() {},
    mounted() {},
    activated() {},
    deactivated() {},
    destroyed() {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
      init (params) {
        if (!params) {
          params = {
            fromStationCode: "",
            toStationCode: "",
            trainCode: "",
            trainDate: "",
          };
        }
        this.fromStationCodeOld = params.fromStationCode;
        this.toStationCodeOld = params.toStationCode;
        this.trainCodeOld = params.trainCode;
        this.trainDateOld = params.trainDate;
      },
      showRequest(){
        let params = {
          fromStationCode: this.fromStationCodeOld ? this.fromStationCodeOld : this.fromStationCode,
          toStationCode: this.toStationCodeOld ? this.toStationCodeOld : this.toStationCode,
          trainCode: this.trainCodeOld ? this.trainCodeOld : this.trainCode,
          trainDate: this.trainDateOld ? this.trainDateOld : this.trainDate,
        };
        if (this.switchOff) {
          consumer_trains_common_queryRailwayTimetable(params).then(res => {
            this.timeTable = res.results;
            this.switchOff = false;
            this.loading = false;
          })
        }
      },
      tableRowClassName({row, rowIndex}) {
        if (row.highlight == false) {
          return 'grey-line'
        } else if (row.highlight == true) {
          return 'active-line'
        } else {
          return 'default-line'
        }
      },
    },
}
