export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      default: '16px'
    }
  },
  data () {
    return {

    };
  },
}
