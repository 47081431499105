import HotelStayDatePicker from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-stay-date-picker/import_latest_version_module';
import HotelKeywordSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-keyword-selector/1.0.2/index.vue";//酒店关键字搜索选择组件
import ReservePopupBox from '@/page/admin/travel-book/home/component/reserve-popup-box/import_latest_version_module'
import ChoseTraveler from '@/component/choose-traveler/1.0.1/index.vue'
import HotelCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/2.1.6';
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_bookingJourneyList from '@/lib/data-service/haolv-default/consumer_apply_bookingJourneyList';
import moment from "moment";
import aes_encrypt from "nllib/src/common/aes/aes_encrypt"; // 新增或修改出差单
import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity'//用户最后一次选择城市
import { awaitWrap } from '@/page/admin/company-management/staff-management/common/unit'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1'//获取出差类型
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import consumer_t_od_hotel_order_interim_hotelCityForbid from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelCityForbid";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
//酒店预定-禁止预定城市
import {hideEvectionTypeChangeTabCompanyIdList} from '@/lib/other/index.js';
export default {
    data() {
        return {
            journeysId: null,
            activeHotelName: '1',
            showTabBar: false,
            userInfo: {},
            loading:  false,
            activeName: '3',
            evectionRequired: 0,
            bookUpperBound: 0,
            businessTravelList: [],
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0,  // 是否可选临近城市 0:否, 1:是
            isFreeApprove: 0, // 是否免审：1.是，0.否

            useTravel: 1, // 是否使用出差， 1 是因公出差， 2是因私出行
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            otherUserIdList: [],

            travelCurrent: null, // 出差当前预设
            shareRoomEvection: null, // 合住人所在的出差单

            hotelCitySelector: {},
            hotelCheckInDate: this.$moment().locale('zh-cn').format('YYYY-MM-DD'),
            hotelCheckOutDate: this.$moment().add(1,'days').locale('zh-cn').format('YYYY-MM-DD'),
            hotelDateArr: [],
            hotelNight: 1,
            hotelKeyword: '',
            hotelStarType: '',
            hotelStarList: [
                {
                    value: '',
                    label: "不限"
                },
                {
                    value: '2',
                    label: "经济型"
                },
                {
                    value: '3',
                    label: "舒适型"
                },
                {
                    value: '4',
                    label: "高档型"
                },
                {
                    value: '5',
                    label: "豪华型"
                }
            ],
            hotelTravelError: false,
            hotelCityError: false,
            hotelDateError: false,
            hotelKeyError: false,
            // hotelUserError: false,
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            timeOptionRange: null,
            activeChooseUserList: [], // 选中的员工id
            evectionDetailType: '001',//'001':因公出差带普通出差单, '002': 因公出差不带出差单, '003': 因公出差带不限出差单 '101':因私出差
            cityCanEdit: true,
            timeCanEdit: true,
            pt: '',//关键字坐标
            filterType: 0,//是否需要带坐标进行搜索
            startPickerOptions: {
                disabledDate: this.disabledDate
            },
            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isTodayMorning: false,//是否今天凌晨入住
        }
    },
    props: {

    },
    components: {
        HotelStayDatePicker,
        HotelKeywordSelector,
        ReservePopupBox,
        HotelCitySelector,
        ChoseTraveler
    },
    created() {
    },
    mounted() {
        const _this = this;
        this.initHotelCitySelector();
        this.$refs['hotelKeywordSelector'].init({
            get_params(){
                return {
                    cityId: _this.hotelCitySelector&&_this.hotelCitySelector.cityId ? _this.hotelCitySelector.cityId : '',
                };
            },
        })
    },
    async activated() {
        this.getBusinessTravel() // 出差预设
        await this.getUserInfo()
        await this.getEvectionAllInfo() // 获取信息
        this.handleClick()
        this.clearParams()
        this.getEvectionType();
        this.canChangeFactor();

        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        if (this.isMorning) {
            //凌晨时进入预订页面需要做的事情
            this.initDateForMorning();
        }
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        useTravel(newVal) {
            this.canChangeFactor();
        },
        travelCurrent(newVal) {
            this.canChangeFactor();
            // if (newVal === null) {
            //     if(this.activeName === '3' && this.evectionDetailType === '001'){
            //         this.initHotelStayDatePicker();
            //     }
            // } else {
            // }
        },
        hotelCitySelector(newVal, oldVal) {
            if (newVal === null) {
                return
            }
            if (newVal.cityId !== '') {
                this.hotelCityError = false
            }
        },
        hotelDateArr(newVal, oldVal) {
            if (newVal === null || newVal.length === 0) {
                return
            }
            if (newVal.length > 0) {
                this.hotelCheckInDate = this.$moment(newVal[0]).format('YYYY-MM-DD');
                this.hotelCheckOutDate = '';
                if(newVal.length === 2) {
                    this.hotelCheckOutDate = this.$moment(newVal[1]).format('YYYY-MM-DD')
                    /*if (this.allowChangeJourneyTime == 1 && this.travelCurrent) {
                        this.hotelCheckOutDate = this.travelCurrent.endDate;
                    } else {
                        this.hotelCheckOutDate = this.$moment(newVal[1]).format('YYYY-MM-DD')
                    }*/

                    // 算时间
                    this.hotelNight = this.$moment(this.hotelCheckOutDate).diff(this.$moment(this.hotelCheckInDate), 'days')
                }
                this.hotelDateError = false

                if (this.isMorning) {
                    let checkInDateStr = this.$moment(newVal[0]).format('YYYY-MM-DD');
                    this.isTodayMorning = checkInDateStr === this.yesterday ? true : false;
                }

                //不限行程，从无出差单切换到有出差单时，选择入离时间后，未选择城市时自动弹出日期弹框
                if (oldVal && oldVal.length === 0 && this.unlimitedJourney === 1 && !this.hotelCitySelector.cityId) {
                    this.$refs.aHotelCitySelector.showCityDailog();
                }
            }
        },
        hotelUserList(newVal, oldVal) {
            if (newVal.length === 0) {
                return
            }
            if (newVal.length > 0) {
                this.hotelUserError = false
            }
        },
    },
    computed: {
        showNotChangeModel() {
            return (this.travelCurrent && this.unlimitedJourney === 0)
        },
        showNotChangeTimeModel() {
            return (this.travelCurrent && this.unlimitedJourney === 0)
        }
    },
    filters: {},
    methods: {
        async getUserInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(get_user_info())
            this.loading = false
            this.userInfo = res.datas
            if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                this.showTabBar = false;
            } else  {
                this.showTabBar = true;
            }
        },
        disabledDate(time) {
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                return time.getTime() < (timestamp - 8.64e7);
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < Date.now() - 1000 * 3600 * 24;
            }
        },
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
        },
        // 获取出差预设列表
        getBusinessTravel() {
            let params = {
                businessType: 3,
            }
            this.loading = true
            consumer_apply_bookingJourneyList(params).then(res => {
                let businessTravelList = res.datas
                this.businessTravelList = businessTravelList
            }).finally(() => {
                this.loading = false
            })
        },
        async getEvectionAllInfo() {
            await this.refundChangePolicyGet();
            //bug: 15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if (this.bookUpperBound === 1 || this.isFreeApprove === 1) {
                this.setUserList()
            }
        },
        // 获取出差规则
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get({clear_cache: false})
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound || 6;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime
            this.changeEvectionCity = res.datas.changeEvectionCity
            this.businessRuleControl = res.datas.businessRuleControl;
            this.isFreeApprove = res.datas.isFreeApprove || 0;
        },
        //获取出差类型
        getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '001'
            }
            getEvectionAllType(evectionParams).then(evectionQuery => {
                this.evectionDetailType = evectionQuery.evectionDetailType;
            })
        },
        canChangeFactor() {
            return getJourneyOptionsCanBeModified({
                situation: '100',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        handleClick() {
            this.travelCurrent = null
            /*if (this.useTravel === 1 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                this.$refs.traveler.setUserList()
            }*/

            this.hotelTravelError = false
            this.hotelCityError = false
            this.hotelDateError = false
            this.hotelKeyError = false

            this.getEvectionType();
            this.canChangeFactor();

            // if (this.activeName === '3' && this.evectionDetailType === '001') {
            //     this.initHotelStayDatePicker();
            // }
        },
        setUserList() {
            this.$refs.traveler.setUserList()
        },
        //初始化酒店入住和离店时间组件
        initHotelStayDatePicker() {
            this.$refs['hotelStayDatePicker'].init({
                __this: this,
                applyJourneyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                timeCanEdit: this.timeCanEdit
            })
        },
        // 改变出差类型时触发
        changeUseTravel(val) {
            this.clearParams()
            this.getEvectionType();
            this.canChangeFactor();
            // if (this.$refs.traveler != undefined) {
            //     this.$refs.traveler.init_switch();
            // }
            // if(this.activeName === '3' && this.evectionDetailType === '001'){
            //     this.initHotelStayDatePicker();
            // }
        },
        async clearParams() {
            this.travelCurrent = null
            let cityObj = {
                cityId: '70082',
                cityName: '北京'
            };
            let res = await consumer_user_historyCity();
            this.hotelCitySelector = res.datas.hotelHistoryInfo ? res.datas.hotelHistoryInfo : cityObj;
            this.setHotelCitySelectorVal();
            this.hotelCheckInDate = this.$moment().format('YYYY-MM-DD')
            this.hotelCheckOutDate = this.$moment().add(1, 'd').format('YYYY-MM-DD')
            this.hotelDateArr = [this.$moment().format('YYYY-MM-DD'), this.$moment().add(1, 'd').format('YYYY-MM-DD')]
            this.hotelNight = 1
            this.hotelKeyword = ''
            this.hotelStarType = ''
            this.hotelTravelError = false
            this.hotelCityError = false
            this.hotelDateError = false
            this.hotelKeyError = false
            this.pt =  '';//关键字坐标
            this.filterType = 0;//是否需要带坐标进行搜索
            //bug:15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if ((this.bookUpperBound === 1 && this.useTravel === 1) || this.isFreeApprove === 1) {
                this.$refs.traveler.setUserList()
            }
        },
        hotelDisabledDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 8.64e7
            if (this.evectionDetailType === '003' || (this.unlimitedJourney === 1 && this.evectionDetailType === '001' && this.allowChangeJourneyTime === 1)) {
                // 获取选中时间
                let timeOptionRange = this.timeOptionRange;
                // 获取时间范围(30天的毫秒数)
                let secondNum = 30 * 24 * 60 * 60 * 1000;
                const startDate = this.travelCurrent.startDate + ' 00:00:00'
                const endDate = this.travelCurrent.endDate + ' 00:00:00'
                console.log('endDate', endDate);
                const startDateValue = moment(startDate).valueOf()
                const endDateValue = moment(endDate).valueOf()
                if (timeOptionRange) {
                    return currentTime > timeOptionRange.getTime() + secondNum || currentTime > endDateValue || currentTime <= timeOptionRange.getTime() || currentTime < startDateValue;
                } else {
                    //没有选中时间（初始化状态）
                    if (this.isMorning) {
                        //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                        let yesterday = new Date();
                        yesterday.setDate(yesterday.getDate() - 1);
                        let timestamp = yesterday.getTime();
                        let isCanSelectYesterday = this.yesterday === this.travelCurrent.startDate ? true : false;
                        if (isCanSelectYesterday) {
                            //凌晨进入页面，如果出差单开始日期为昨天，则可选昨天为入住日期
                            return currentTime < (timestamp - 8.64e7) || (currentTime > endDateValue);
                        } else {
                            return currentTime < (timestamp - 8.64e7) || (currentTime < startDateValue) || (currentTime > endDateValue);
                        }
                    } else {
                        //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                        return (currentTime < nowDate) || (currentTime < startDateValue) || (currentTime > endDateValue)
                    }
                }
            } else {
                // 没选出差单
                // 获取选中时间
                let timeOptionRange = this.timeOptionRange;
                // 获取时间范围(30天的毫秒数)
                let secondNum = 30 * 24 * 60 * 60 * 1000;
                if (timeOptionRange) {
                    //如果有选中时间 设置超过选中时间后的30天||超过选中前的30天||大于当前时间 不可选
                    return currentTime > timeOptionRange.getTime() + secondNum || currentTime <= timeOptionRange.getTime();
                } else {
                    //如果没有选中时间（初始化状态） 设置当前时间后的时间不可选
                    if (this.isMorning) {
                        //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                        let yesterday = new Date();
                        yesterday.setDate(yesterday.getDate() - 1);
                        let timestamp = yesterday.getTime();
                        return currentTime < (timestamp - 8.64e7);
                    } else {
                        //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                        return currentTime < nowDate;
                    }
                }
            }
        },
        hotelOnPick({ maxDate, minDate }) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        async chooseJourney(newVal) {
            if (this.travelCurrent && this.travelCurrent.journeys) {
                this.travelCurrent.journeys.forEach((item,index) => {
                    if (item.businessType == 3) {
                        this.journeysId = item.id;
                    }
                })
            }
            const __this = this;
            this.getEvectionType();
            await this.canChangeFactor();
            if (this.travelCurrent === null) {
                this.clearParams()
            } else {
                this.flightTravelError = false
                this.hotelTravelError = false
                this.trainTravelError = false

                //不限行程时,城市和时间默认为空
                if (this.travelCurrent.active.journeyType === 1) {
                    this.hotelCitySelector = {
                        cityId: '',
                        cityName: '',
                    }
                    this.setHotelCitySelectorVal();
                    this.hotelDateArr = []
                    this.hotelCheckInDate = ''
                    this.hotelCheckOutDate = ''
                    /*if (this.allowChangeJourneyTime == 0) {
                        this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                    } else {
                        this.hotelCheckOutDate = this.travelCurrent.endDate
                    }*/

                    // 算时间
                    this.hotelNight = 0

                    if(this.activeName === '3'){
                        this.hotelCheckInDate = '';
                        this.hotelCheckOutDate = '';
                    }

                    setTimeout(() => {
                        this.$refs.aHotelCitySelector.showCityDailog();
                    }, 200)

                    return;
                }

                // 对时间进行赋值
                const businessType = this.travelCurrent.active.businessType
                // 酒店
                this.hotelCitySelector = {
                    cityId: this.travelCurrent.active.toCityId,
                    cityName: this.travelCurrent.active.toCityName,
                }
                this.setHotelCitySelectorVal();
                this.hotelDateArr = [this.travelCurrent.active.departTime, this.travelCurrent.active.returnTime]
                this.hotelCheckInDate = this.travelCurrent.active.firstDepartTime
                this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                /*if (this.allowChangeJourneyTime == 0) {
                    this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                } else {
                    this.hotelCheckOutDate = this.travelCurrent.endDate
                }*/

                // 算时间
                this.hotelNight = this.$moment(this.hotelCheckOutDate).diff(this.$moment(this.hotelCheckInDate), 'days')

                if(this.activeName === '3'){
                    this.hotelCheckInDate = this.travelCurrent.active.firstDepartTime;
                    this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                    /*if (this.allowChangeJourneyTime == 0) {
                        this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                    } else {
                        this.hotelCheckOutDate = this.travelCurrent.endDate
                    }*/
                    this.hotelCheckOutDate = this.travelCurrent.active.firstReturnTime
                }
            }
            if (this.evectionDetailType === '001') {
                this.initHotelStayDatePicker();
            }
        },
        // 判断是否能修改
        checkEvectionEdit() {
            if (!this.cityCanEdit) {
                this.$message({
                    type: "warning",
                    message: '不能修改出差单既定行程'
                })
            }
        },
        checkEvectionTimeEdit() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程时间'
            })
        },
        async toSearchHotel() {
            // if (this.useTravel === 1 && (this.evectionRequired && this.travelCurrent === null)) {
            //     this.hotelTravelError = true
            //     return
            // }
            if (JSON.stringify(this.hotelCitySelector) === '{}' || this.hotelCitySelector === null || !this.hotelCitySelector.cityId) {
                this.hotelCityError = true
                return
            }
            if (this.hotelCheckInDate === '' || this.hotelCheckOutDate === '') {
                this.hotelDateError = true
                return
            }
            if (this.useTravel === 1 && this.travelCurrent === null && this.isFreeApprove === 0 && this.activeChooseUserList.length > this.bookUpperBound) {
                this.$message({
                    type: "warning",
                    message: '出行人不能大于帮订人数'
                })
                return
            }
            if (this.isFreeApprove === 1 && this.activeChooseUserList.length > 6) {
                this.$message({
                    type: "warning",
                    message: '出行人不能大于帮订人数'
                })
                return
            }

            if(this.hotelCitySelector.cityId === '') {
                this.$message({
                    type: "warning",
                    message: '请选择城市'
                })
                return
            }

            if(this.hotelCheckInDate === '' || this.checkOutDate === '') {
                this.$message({
                    type: "warning",
                    message: '请选择入离时间'
                })
                return
            }

            let isValidCity = await this.checkCity();
            if (!isValidCity) {
                return;
            }

            let queryParams = {}
            const key = 'params';

            if (this.useTravel === 1) {
                // 组装合住人信息
                let sharedUserList = [];
                console.log(this.otherUserIdList, this.shareRoomEvection);
                if (this.otherUserIdList.length > 0 && this.shareRoomEvection) {
                    sharedUserList.push({
                        userId: this.otherUserIdList[0],
                        evectionNo: this.shareRoomEvection.evectionNo,
                        personType: 2,
                        journeyId: this.shareRoomEvection.active.id,
                    })
                }
                let params = {
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    endDate: this.$moment(this.hotelCheckOutDate).format('YYYY-MM-DD'),
                    evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : '',
                    evectionType: this.useTravel === 1 ? 2 : 1,
                    reason: this.travelCurrent ? this.travelCurrent.reason : '',
                    startDate: this.$moment(this.hotelCheckInDate).format('YYYY-MM-DD'),
                    travellerList: this.activeChooseUserList,
                    personType: 2,
                    sharedUserList,
                    sharedList: this.otherUserIdList,
                    sharedStatus: this.otherUserIdList.length > 0 ? 1 : 2,
                }
                let res = await consumer_evection_addOrUpdate(params)
                let returnTime = '';
                if (this.travelCurrent) {
                    if (this.allowChangeJourneyTime === 0) {
                        returnTime = this.$moment(this.travelCurrent.active.returnTime).format('YYYY-MM-DD');
                    } else {
                        returnTime = this.$moment(this.travelCurrent.endDate).format('YYYY-MM-DD');
                    }
                }
                queryParams = {
                    cityId: this.hotelCitySelector.cityId,
                    cityName: this.hotelCitySelector.cityName,
                    checkInDate: this.$moment(this.hotelCheckInDate).format('YYYY-MM-DD'),
                    checkOutDate: this.$moment(this.hotelCheckOutDate).format('YYYY-MM-DD'),
                    departTime: this.travelCurrent?this.$moment(this.travelCurrent.active.departTime).format('YYYY-MM-DD'):'',
                    returnTime: returnTime,
                    keyword: this.hotelKeyword,
                    hotelStar: this.hotelStarType,
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    redirectType: '000',
                    journeyId: this.travelCurrent?this.travelCurrent.active.id:0,
                    startRangeDate: this.travelCurrent?this.travelCurrent.startDate : '',
                    endRangeDate: this.travelCurrent?this.travelCurrent.endDate : '',
                    pt: this.pt,
                    filterType: this.filterType
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-hotel-search',
                    query: {
                        params: encrypted,
                        evectionNo: res.datas,
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                        evectionRequired: this.evectionRequired,
                        workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                        journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : 0, // 0 普通单， 1 不限行程单
                        unlimitedJourney: this.unlimitedJourney ? this.unlimitedJourney : 0,//系统设置：0：限制行程；1：不限行程
                        pt_from_type: '000',
                        journeysId: this.journeysId,
                        shareJourneyId: this.shareRoomEvection ? this.shareRoomEvection.active.id : 0,
                        shareEvectionNo: this.shareRoomEvection ? this.shareRoomEvection.evectionNo : '',
                    }
                })
            } else {
                queryParams = {
                    cityId: this.hotelCitySelector.cityId,
                    cityName: this.hotelCitySelector.cityName,
                    checkInDate: this.$moment(this.hotelCheckInDate).format('YYYY-MM-DD'),
                    checkOutDate: this.$moment(this.hotelCheckOutDate).format('YYYY-MM-DD'),
                    keyword: this.hotelKeyword,
                    hotelStar: this.hotelStarType,
                    redirectType: '000',
                    pt: this.pt,
                    filterType: this.filterType,
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: "admin-hotel-search",
                    query: {
                        cityId: this.hotelCitySelector.cityId,
                        cityName: this.hotelCitySelector.cityName,
                        checkInDate: this.$moment(this.hotelCheckInDate).format('YYYY-MM-DD'),
                        checkOutDate: this.$moment(this.hotelCheckOutDate).format('YYYY-MM-DD'),
                        keyword: this.hotelKeyword,
                        evectionType: 1,
                        pt_from_type: '000'
                    },
                })
            }
        },
        //
        _getJourneyInfo() {
            // 获取出差信息
            let params = {
                "departTime": this.travelCurrent.active.departTime,
                "returnTime": this.travelCurrent.active.returnTime,
                "toCityId": this.hotelCitySelector.cityId,
            };
            this.$refs.traveler.setHotelJourneyToShareRoomAndShowPopup(params);
        },
        getSelectedStaffIdList(resObj) {
            this.activeChooseUserList = resObj.selectedStaffIdList;
            this.otherUserIdList = resObj.otherUserIdList;
            this.shareRoomEvection = resObj.shareRoomEvection;
        },
        initHotelCitySelector() {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.init({
                mock_data: {
                    enable: false,
                    wait_seconds: 0.5,
                },
                panel_select_entity: {
                    width: `414`,
                },
                el_autocomplete: {
                    placeholder: `请选择目的城市`,
                },
                event_handler: {
                    change_event_handler(args) {
                        __this.hotelCitySelector = {
                            cityId: args.id,
                            cityName: args.name
                        }
                        if (args.type !== '5') {
                            __this.hotelKeyword = args.key ? args.key : '';
                        } else {
                            __this.hotelKeyword = '';
                        }
                        if(args.entity.filterType === 1) {
                            __this.pt = args.entity.location;
                        }
                        __this.filterType = args.entity.filterType;
                        __this.$refs.hotelKeywordSelector.getSearchThinkList();

                        setTimeout(() => {
                            //检查当前城市是否可预订
                            __this.checkCity();
                            //不限行程，未选择入离时间，选择城市后，自动弹出日期控件
                            if (__this.hotelCitySelector.cityId && (__this.hotelCheckInDate === '' || __this.hotelCheckOutDate === '')) {
                                if (__this.$refs.datePick003) {
                                    __this.$refs.datePick003.focus();
                                }
                            }
                        }, 200)

                    },
                },
            });
        },
        setHotelCitySelectorVal() {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.set_value({
                "id": __this.hotelCitySelector.cityId,
                "name": __this.hotelCitySelector.cityName,
                "entity": {
                    "cityId": __this.hotelCitySelector.cityId,
                    "cityCn": __this.hotelCitySelector.cityName,
                    "cityPinyin": "",
                    "cityPinyinSimp": "",
                    "initial": "",
                    "isHot": "",
                    "cityName": null,
                    "key": null
                },
            });
        },
        changeKeyword(val) {
            this.pt = '';
            this.filterType = val.filterType;
            if(val.filterType === 1) {
                this.pt = val.location;
            }
        },

        //检查是否可查询该城市的酒店
        async checkCity() {
            let isOk = true;
            if (this.useTravel === 1) {
                let res = await consumer_t_od_hotel_order_interim_hotelCityForbid({
                    cityId: this.hotelCitySelector.cityId,
                    companyId: this.userInfo.companyId
                });
                if (res.datas.isForbid) {
                    this.$message({
                        type: "warning",
                        message: res.datas.forbidMsg
                    })
                    isOk = false;
                }
            }
            return isOk;
        }
    }
}
