import consumer_apply_newWorkflowDetail from '@/lib/data-service/haolv-default/consumer_apply_newWorkflowDetail'; // 出差单审批
import consumer_overproof_newDetail from '@/lib/data-service/haolv-default/consumer_overproof_newDetail'; // 超标审批
import consumer_journey_bookingApplyDetails from '@/lib/data-service/haolv-default/consumer_journey_bookingApplyDetails'; // 预订审批
import consumer_journey_applyChangeDetails from '@/lib/data-service/haolv-default/consumer_journey_applyChangeDetails'; // 改签审批
import consumer_journey_applyRefundDetails from '@/lib/data-service/haolv-default/consumer_journey_applyRefundDetails'; // 退票审批
import WorkflowProcessLineForArray from "@/component/workflow-process-line-for-array/index.vue";
import FlightItem from "@/page/admin/office/approve-detail-for-workflow/component/flight-item/index.vue";
import TrainItem from "@/page/admin/office/approve-detail-for-workflow/component/train-item/index.vue";
import HotelItem from "@/page/admin/office/approve-detail-for-workflow/component/hotel-item/index.vue";
import FlightChangeItem from "@/page/admin/office/approve-detail-for-workflow/component/flight-change-item/index.vue";
import TrainChangeItem from "@/page/admin/office/approve-detail-for-workflow/component/train-change-item/index.vue";
import {formatStartNodeShow, operablePermissions, getProcessInstanceInfo} from "@/lib/data-service/haolv-default/design";
import consumer_budget_getBudgetInfo from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInfo";
import consumer_budget_getBudgetOrgList from "@/lib/data-service/haolv-default/consumer_budget_getBudgetOrgList";
import consumer_budget_getBudgetInsurance from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance";
import consumer_budget_getBudgetPro from "@/lib/data-service/haolv-default/consumer_budget_getBudgetPro";
import consumer_budget_getBudgetItem from "@/lib/data-service/haolv-default/consumer_budget_getBudgetItem";
// 新审批流操作
import AgreeModal from '@/component/process/AgreeModal';
import CommentModal from '@/component/process/CommentModal';
import DelegateModal from '@/component/process/DelegateModal';
import AssigneeModal from '@/component/process/AssigneeModal';
import ResolveModal from '@/component/process/ResolveModal';
import RefuseModal from '@/component/process/RefuseModal';
import RollbackModal from '@/component/process/RollbackModal';
import AddMultiModal from '@/component/process/AddMultiModal';
import QueryMultiUserModal from '@/component/process/QueryMultiUserModal';
import RevokeModal from '@/component/process/RevokeModal'
import DeleteMultiModal from '@/component/process/DeleteMultiModal';
import consumer_budget_getBudgetInfoByCondition
    from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInfoByCondition";
import moment from 'moment';
import ColorIcon from "@/component/color-icon/index.vue";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
export default {
    data() {
        return {
            loading: false,
            status: 0, // 审批状态，1.待我审，2.已审批  (待我审批那支才要)
            applyType: 1, // 1我的申请，2待我审批
            approveType: 0, // 审批类型： 1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请， 6.授信申请， 7.预订申请
            applyNo: '',
            detail: {},
            applyName: '',
            activities: [],
            processInfo: {},
            opaList: [],
            modalConfig: {
                agreeVisible: false,
                commentVisible: false,
                delegateVisible: false,
                assigneeVisible: false,
                resolveVisible: false,
                refuseVisible: false,
                rollbackVisible: false,
                addMultiVisible: false,
                queryMultiUserVisible: false,
                revokeVisible: false,
                deleteMultiVisible: false
            },
            moment,
            // 预算
            budgetDetailSwitch: false,
            fillRegionSwitch: true,
            polar: null,
            budgetStartDate: '',
            budgetProList: [],
            budgetOrgList: [],
            budgetItem: [],
            budgetInsuranceList: [],
            budgetOrg: '',
            budgetItemList: [
                {code: 1,name: '管理'},
                {code: 2,name: '理赔'},
                {code: 3,name: '销售'},
            ],
            budgetInfoByCondition: null,
            budgetLoading: false,
            formNew: {
                budgetStartDate: '',
                budgetOrg: '',
                budgetStartMonth: '',
                budgetEndMonth: '',
                budgetInsuranceCode: '',
                budgetInsuranceName: '',
                budgetItemCode: '',
                budgetItemName: '',
                budgetOrgCode: '',
                budgetOrgName: '',
                budgetProCode: '',
                budgetProName: '',
                budgetInfoByCondition: '',
            },
            form: {
                budgetStartMonth: '',   // 预算期间月份开始时间
                budgetEndMonth: '',    // 预算期间月份结束时间
                budgetInsuranceCode: '',   // 预算险类
                budgetInsuranceName: '',
                budgetItemCode: '',   // 科目
                budgetItemName: '',
                budgetOrgCode: '',   // 预算组织
                budgetOrgName: '',
                budgetProCode: '',   // 项目
                budgetProName: '',
                currentPage: 1,
                pageSize: 1,
            },
            dialogBudget: false,
            pickerOptions:{
                disabledDate: () => false,
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && !maxDate) {
                        const oneYear = 365 * 24 * 60 * 60 * 1000;
                        this.pickerOptions.disabledDate = time => {
                            return time.getTime() < minDate.getTime() || time.getTime() > minDate.getTime() + oneYear;
                        };
                    } else {
                        this.pickerOptions.disabledDate = () => false;
                    }
                },
            },
        }
    },
    props: {},
    components: {
        WorkflowProcessLineForArray,
        FlightItem,
        TrainItem,
        HotelItem,
        FlightChangeItem,
        TrainChangeItem,
        CommentModal,
        DelegateModal,
        AssigneeModal,
        ResolveModal,
        RefuseModal,
        RollbackModal,
        AddMultiModal,
        QueryMultiUserModal,
        RevokeModal,
        DeleteMultiModal,
        AgreeModal,
        ColorIcon,
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.applyNo = this.$route.query.applyNo;
        this.applyType = parseInt(this.$route.query.applyType);
        this.approveType = parseInt(this.$route.query.approveType);
        this.status = parseInt(this.$route.query.status || 0);
        this.taskId = this.$route.query.taskId;
        this._init();
        //consumer_apply_newWorkflowDetail({applyNo: this.applyNo, 'approveType': 2, status:1}).then(res=>{});
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        showMoreOpa() {
            let moreBtnList = [];
            let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
            this.opaList.forEach(value => {
                if (moreBtn.indexOf(value.key) > -1 && value.checked) {
                    moreBtnList.push(value);
                }
            })
            return moreBtnList;
        },
    },
    filters: {},
    methods: {
        async _init() {
            this.loading = true;
            if (this.approveType === 1) {
                let [err, res] = await awaitWrap(consumer_apply_newWorkflowDetail({applyNo: this.applyNo, 'approveType': this.applyType, status: this.status}));
                if (err) {
                    this.loading = false;
                    return
                }
                this.detail = res['datas'] || {};
            }
            if (this.approveType === 2) {
                let [err, res]  = await awaitWrap(consumer_overproof_newDetail({applyNo: this.applyNo, 'approveType': this.applyType, status: this.status}));
                if (err) {
                    this.loading = false;
                    return
                }
                let detail = res['datas'] || {};
                if (detail.flightExcessItineraryList) {
                    detail.flightExcessItineraryList.forEach(value=>{
                        value.discount = parseFloat(value.discount).toFixed(1).toString();
                    })
                }
                this.detail = detail;
            }
            if (this.approveType === 7) {
                let [err, res]  = await awaitWrap(consumer_journey_bookingApplyDetails({applyNo: this.applyNo, 'approveType': this.applyType, status: this.status}));
                if (err) {
                    this.loading = false;
                    return
                }
                this.detail = res['datas'] || {};
            }
            if (this.approveType === 4 || this.approveType === 8) {
                let [err, res]  = await awaitWrap(consumer_journey_applyChangeDetails({applyNo: this.applyNo, 'approveType': this.applyType, status: this.status}));
                if (err) {
                    this.loading = false;
                    return
                }
                let detail = res['datas'] || {};
                if (detail.flightExcessItineraryList) {
                    detail.flightExcessItineraryList.forEach(value=>{
                        value.discount = parseFloat(value.discount).toFixed(1).toString();
                    })
                }
                if (detail.changeFlightExcessItineraryList) {
                    detail.changeFlightExcessItineraryList.forEach(value=>{
                        value.discount = parseFloat(value.discount).toFixed(1).toString();
                    })
                }
                this.detail = detail;
            }
            if (this.approveType === 5 || this.approveType === 9) {
                let [err, res]  = await awaitWrap(consumer_journey_applyRefundDetails({applyNo: this.applyNo, 'approveType': this.applyType, status: this.status}));
                if (err) {
                    this.loading = false;
                    return
                }
                let detail = res['datas'] || {};
                if (detail.flightExcessItineraryList) {
                    detail.flightExcessItineraryList.forEach(value=>{
                        value.discount = parseFloat(value.discount).toFixed(1).toString();
                    })
                }
                this.detail = detail;
            }
            this.loading = false;

            this.processInfo = {
                processInstanceId: this.applyNo,
                taskId: this.taskId,
                signFlag: null,
                currentUserInfo: {
                    id: this.$store.state.userInfo.id,
                    name: this.$store.state.userInfo.realName,
                },
            }

            // 只有审批中才能调
            if (this.applyType === 2 && this.status === 1 && (this.detail.status === 1 || this.detail.status === 3)) {
                operablePermissions({
                    processInstanceId: this.applyNo,
                    userId: this.$store.state.userInfo.id,
                    taskId: this.taskId
                }).then((result)=>{
                    this.opaList = result.result;
                })
            }

            getProcessInstanceInfo(this.applyNo, this.taskId).then(resultInfo=>{
                this.processInfo.formData = { ...resultInfo.result.formData };
            })

            formatStartNodeShow({
                processInstanceId: this.applyNo,
                userId: this.$store.state.userInfo.id,
                deptId: this.$store.state.userInfo.deptId,
                budgetAmount: this.detail.budgetAmount || 0,
            }).then(res=>{
                let list = res.result;
                let form = {
                    line: list
                }
                // 获取待谁审
                list.forEach(element=>{
                    if (element['status'] === 1) {
                        let applyNameList = [];
                        element['userVoList'].forEach((item) =>{
                            applyNameList.push(item['staffName']);
                        });
                        this.applyName = applyNameList.join('、');
                    }
                })
                this.activities = list;
                this.$refs.workflowProcessLineForArray.init(form);
            })
        },
        _lookUrl(url) {
            console.log(url);
            window.open(url);
        },
        _setIcon(url) {
            let urlList = url.split('.');
            let type = urlList[urlList.length - 1];
            if (['jpg','png','bmp','jpeg'].indexOf(type) > -1) {
                return 'haolv-icona-zu4793';
            }
            if (['xls', 'xlsx'].indexOf(type) > -1) {
                return 'haolv-icona-zu4794';
            }
            if (["doc", "docx"].indexOf(type) > -1) {
                return 'haolv-icona-zu4795';
            }
            return 'haolv-icona-zu4795';
        },
        _carTitle(val) {
            let text = '';
            switch (val['carPolicyType']) {
                case 1:
                    text = '出差用车';
                    break;
                case 2:
                    text = '加班用车';
                    break;
                case 3:
                    text = '接送服务';
                    break;
                case 4:
                    text = '代叫用车';
                    break;
                case 5:
                    text = '定点通勤';
                    break;
                case 6:
                    text = '外勤用车';
                    break;
            }
            return text;
        },
        _newPass(val) {
            switch (val.key) {
                case 'pass':
                    // 通过
                    this._onAgree();
                    break
                case 'refuse':
                    // 拒绝
                    this._onRefuse();
                    break
                case 'reject':
                    // 驳回
                    this._onRollback();
                    break
                case 'frontJoin':
                    // 委派
                    this._onDelegateTask();
                    break
                case 'backJoin':
                    // 转办
                    this._onAssignee();
                    break
                case 'addAssignee':
                    // 加签
                    this._onAddMulti();
                    break
                case 'delAssignee':
                    // 减签
                    this._onDeleteMulti();
                    break
            }
        },
        _handleCommand(val) {
            this.opaList.forEach(value => {
                if (value.key === val) {
                    this._newPass(value);
                }
            })
        },
        _onAgree() {
            this.modalConfig.agreeVisible = true;
        },
        _onDelegateTask() {
            this.modalConfig.delegateVisible = true;
        },
        _onResolveTask() {
            this.modalConfig.resolveVisible = true
        },
        _onRefuse() {
            this.modalConfig.refuseVisible = true
        },
        _onRevoke() {
            this.modalConfig.revokeVisible = true;
        },

        _onAssignee() {
            this.modalConfig.assigneeVisible = true
        },
        _onRollback() {
            this.modalConfig.rollbackVisible = true;
        },
        _onAddMulti() {
            this.modalConfig.addMultiVisible = true;
        },
        _onQueryMultiUsersInfo() {
            this.modalConfig.queryMultiUserVisible = true;
        },
        _onDeleteMulti() {
            this.modalConfig.deleteMultiVisible = true;
        },
        // 添加评论
        _onComments() {
            this.modalConfig.commentVisible = true;
        },

        _success() {
            this._back();
        },
        _back() {
            this.$router.go(-1);
        },
        async _showDialogBudget() {
            this.dialogBudget = true;
            this.budgetStartDate = this.formNew.budgetStartDate;
            this.budgetOrg = this.formNew.budgetOrg;
            this.form.budgetStartMonth = this.formNew.budgetStartMonth;
            this.form.budgetEndMonth = this.formNew.budgetEndMonth;
            this.form.budgetInsuranceCode = this.formNew.budgetInsuranceCode;
            this.form.budgetInsuranceName = this.formNew.budgetInsuranceName;
            this.form.budgetItemCode = this.formNew.budgetItemCode;
            this.form.budgetItemName = this.formNew.budgetItemName;
            this.form.budgetOrgCode = this.formNew.budgetOrgCode;
            this.form.budgetOrgName = this.formNew.budgetOrgName;
            this.form.budgetProCode = this.formNew.budgetProCode;
            this.form.budgetProName = this.formNew.budgetProName;
            this.budgetInfoByCondition = this.formNew.budgetInfoByCondition;
            this.budgetStartDate = this.formNew.budgetStartDate;
            if (this.polar == null) {
                this.budgetLoading = true;
                consumer_budget_getBudgetInfo({evectionNo: detail.evectionNo}).then(async (res) => {
                    this.budgetInfoByCondition = res.datas;
                    // 预算期间
                    this.budgetStartDate = [res.datas.budgetStartMonth,res.datas.budgetEndMonth];
                    // 条形图
                    let monthCodeBox = [];
                    let monthDataBox = [];
                    let monthMaxBox = [];
                    res.datas.collectAmountList.forEach((CItem,CIndex) => {
                        monthCodeBox.push(Number(CItem.budgetMonth.split('-')[1]));
                        monthDataBox.push(CItem);
                        monthMaxBox.push(Number(CItem.usedSettlingAmount) + Number(CItem.usedSettledAmount) + Number(CItem.occupyAmount) + Number(CItem.budgetAmount));
                    });
                    monthMaxBox.sort((a, b) => b - a);
                    let blueData = [];   // 发生未结算数
                    let orangeData = [];   // 发生已结算数
                    let greenData = [];   // 占用数
                    let redData = [];   // 预算数
                    let xAxisData = [];
                    let emphasisStyle = {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0,0,0,0.3)'
                        }
                    };
                    for (let i = 0; i < 12; i++) {
                        xAxisData.push(`${i+1}月`);
                        if (monthCodeBox.indexOf(i+1) !== -1) {
                            blueData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettlingAmount);
                            orangeData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettledAmount);
                            greenData.push(monthDataBox[monthCodeBox.indexOf(i+1)].occupyAmount);
                            redData.push(monthDataBox[monthCodeBox.indexOf(i+1)].budgetAmount);
                        } else {
                            blueData.push(0);
                            orangeData.push(0);
                            greenData.push(0);
                            redData.push(0);
                        }
                    }
                    let blueDataMax = [];   // 发生未结算数--调整后数据
                    let orangeDataMax = [];   // 发生已结算数--调整后数据
                    let greenDataMax = [];   // 占用数--调整后数据
                    let redDataMax = [];   // 预算数--调整后数据

                    let adjustmentFunction = function (dataList) {
                        let box = [];
                        dataList.forEach((item,index) => {
                            if (item / monthMaxBox[0] * 100 < 5 && item > 0) {
                                box[index] = monthMaxBox[0] * 0.05;
                            } else {
                                box[index] = item;
                            }
                        });
                        return box;
                    };

                    blueDataMax = adjustmentFunction(blueData);
                    orangeDataMax = adjustmentFunction(orangeData);
                    greenDataMax = adjustmentFunction(greenData);
                    redDataMax = adjustmentFunction(redData);

                    this.polar = {
                        title: {
                            text: ''
                        },
                        color: ['#74a0fa','#f7a945','#73deb4','#d9001b'],
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: (params) => {
                                let box = params.componentIndex === 0 ? blueData : params.componentIndex == 1 ? orangeData : params.componentIndex == 2 ? greenData : redData;
                                let d = `${params.seriesName}<br>`;
                                let item = [];
                                item.push(`${box[params.dataIndex]}<br>`);
                                return d + item.join('');
                            }
                        },
                        grid: {
                            bottom: 100,
                        },
                        xAxis: {
                            type: 'category',
                            data: xAxisData,
                            name: '月份',
                            axisLine: { onZero: true },
                            splitLine: { show: false },
                            splitArea: { show: false },
                        },
                        yAxis: {},
                        series: [
                            {
                                name: '发生未结算数',
                                type: 'bar',
                                stack: 'one',
                                emphasis: emphasisStyle,
                                data: blueDataMax
                            },
                            {
                                name: '发生已结算数',
                                type: 'bar',
                                stack: 'one',
                                emphasis: emphasisStyle,
                                data: orangeDataMax
                            },
                            {
                                name: '占用数',
                                type: 'bar',
                                stack: 'one',
                                emphasis: emphasisStyle,
                                data: greenDataMax
                            },
                            {
                                name: '预算数',
                                type: 'bar',
                                stack: 'one',
                                emphasis: emphasisStyle,
                                data: redDataMax
                            },
                        ],
                    };
                    this.$nextTick(() => {
                        this.$refs.chart.resize({
                            width: 750,
                            height: 400
                        });
                    });
                    // 条形图--end

                    await this.$store.state.userInfoDefer.promise;
                    let budgetFunction = (list) => {
                        let box = [];
                        if (list) {
                            list.forEach((item,index) => {
                                box.push({value: item.budgetOrgId,label: item.budgetOrgName,});
                                if (item.childrenList && item.childrenList.length !== 0) {
                                    box[index].children = [];
                                    box[index].children = budgetFunction(item.childrenList);
                                }
                            });
                        }
                        return box;
                    };
                    let budgetOrgBox = [];
                    let budgetOrgFunction = (list,boxTwo) => {
                        let box = boxTwo ? [...boxTwo] : [];
                        for (let i = 0; i < list.length; i++) {
                            box.push(list[i].value);
                            if (list[i].children && list[i].children.length !== 0) {
                                budgetOrgFunction(list[i].children,box)
                            } else {
                                if (list[i].label === this.budgetInfoByCondition.budgetOrgName) {
                                    budgetOrgBox = box;
                                    return;
                                }
                                budgetOrgBox.push(box);
                                box = [...boxTwo];
                            }
                        }
                    };
                    this.budgetOrgList = [];
                    consumer_budget_getBudgetOrgList({companyId: this.$store.state.userInfo.companyId}).then(res => {
                        res.datas.departmentListResults.forEach((item,index) => {
                            let dataBox = {
                                value: item.budgetOrgId,
                                label: item.budgetOrgName,
                                children: [],
                            };
                            dataBox.children = budgetFunction(item.childrenList);
                            this.budgetOrgList.push(dataBox);
                        });
                        budgetOrgFunction(this.budgetOrgList);
                        this.budgetOrg = budgetOrgBox;
                        this.changeBudgetOrg(this.budgetOrg);
                        this.formNew.budgetOrg = this.budgetOrg;
                    });
                    this.form.budgetItemName = this.budgetInfoByCondition.budgetItemName;
                    this.form.budgetInsuranceName = this.budgetInfoByCondition.budgetInsuranceName;
                    this.form.budgetProName = this.budgetInfoByCondition.budgetProName;
                }).finally(() => {
                    this.budgetLoading = false;
                    this.formNew.budgetStartDate = this.budgetStartDate;
                    this.formNew.budgetStartMonth = this.form.budgetStartMonth;
                    this.formNew.budgetEndMonth = this.form.budgetEndMonth;
                    this.formNew.budgetInsuranceCode = this.form.budgetInsuranceCode;
                    this.formNew.budgetInsuranceName = this.form.budgetInsuranceName;
                    this.formNew.budgetItemCode = this.form.budgetItemCode;
                    this.formNew.budgetItemName = this.form.budgetItemName;
                    this.formNew.budgetOrgCode = this.form.budgetOrgCode;
                    this.formNew.budgetOrgName = this.form.budgetOrgName;
                    this.formNew.budgetProCode = this.form.budgetProCode;
                    this.formNew.budgetProName = this.form.budgetProName;
                    this.formNew.budgetInfoByCondition = this.budgetInfoByCondition;
                });
                consumer_budget_getBudgetInsurance().then(res => {
                    this.budgetInsuranceList = res.datas;
                });
                consumer_budget_getBudgetPro().then(res => {
                    this.budgetProList = res.datas;
                });
                consumer_budget_getBudgetItem().then(res => {
                    this.budgetItem = res.datas;
                });
            } else {

            }
        },
        _changeBudgetOrg(value) {
            let funBox = (dataList,code) => {
                let box = null;
                dataList.forEach((item,index) => {
                    if (item.value === code) {
                        box = item;
                        if (item.children) {
                            box = item.children;
                        }
                    }
                });
                return box;
            };
            let dataBox = this.budgetOrgList;
            value.forEach((item,index) => {
                dataBox = funBox(dataBox,item);
                if (dataBox.label) {
                    this.form.budgetOrgCode = '';
                    this.form.budgetOrgName = dataBox.label;
                }
            });
        },
        _queryClick() {
            if (!this.budgetStartDate) {
                this.$message.error('请选择预算区间');
                return;
            }

            this.formNew.budgetStartDate = this.budgetStartDate;
            this.formNew.budgetOrg = this.budgetOrg;
            this.formNew.budgetStartMonth = this.form.budgetStartMonth;
            this.formNew.budgetEndMonth = this.form.budgetEndMonth;
            this.formNew.budgetInsuranceCode = this.form.budgetInsuranceCode;
            this.formNew.budgetInsuranceName = this.form.budgetInsuranceName;
            this.formNew.budgetItemCode = this.form.budgetItemCode;
            this.formNew.budgetItemName = this.form.budgetItemName;
            this.formNew.budgetOrgCode = this.form.budgetOrgCode;
            this.formNew.budgetOrgName = this.form.budgetOrgName;
            this.formNew.budgetProCode = this.form.budgetProCode;
            this.formNew.budgetProName = this.form.budgetProName;
            this.formNew.budgetInfoByCondition = this.budgetInfoByCondition;

            this.form.budgetStartMonth = this.budgetStartDate[0];
            this.form.budgetEndMonth = this.budgetStartDate[1];
            this.budgetLoading = true;
            consumer_budget_getBudgetInfoByCondition(this.form).then(res => {
                this.budgetInfoByCondition = res.datas;
                let monthCodeBox = [];
                let monthDataBox = [];
                let monthMaxBox = [];
                res.datas.collectAmountList.forEach((CItem,CIndex) => {
                    monthCodeBox.push(Number(CItem.budgetMonth.split('-')[1]));
                    monthDataBox.push(CItem);
                    monthMaxBox.push(Number(CItem.usedSettlingAmount) + Number(CItem.usedSettledAmount) + Number(CItem.occupyAmount) + Number(CItem.budgetAmount));
                });
                monthMaxBox.sort((a, b) => b - a);
                let blueData = [];   // 发生未结算数
                let orangeData = [];   // 发生已结算数
                let greenData = [];   // 占用数
                let redData = [];   // 预算数
                let xAxisData = [];
                let emphasisStyle = {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0,0,0,0.3)'
                    }
                };
                for (let i = 0; i < 12; i++) {
                    xAxisData.push(`${i+1}月`);
                    if (monthCodeBox.indexOf(i+1) != -1) {
                        blueData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettlingAmount);
                        orangeData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettledAmount);
                        greenData.push(monthDataBox[monthCodeBox.indexOf(i+1)].occupyAmount);
                        redData.push(monthDataBox[monthCodeBox.indexOf(i+1)].budgetAmount);
                    } else {
                        blueData.push(0);
                        orangeData.push(0);
                        greenData.push(0);
                        redData.push(0);
                    }
                }
                let blueDataMax = [];   // 发生未结算数--调整后数据
                let orangeDataMax = [];   // 发生已结算数--调整后数据
                let greenDataMax = [];   // 占用数--调整后数据
                let redDataMax = [];   // 预算数--调整后数据

                let adjustmentFunction = function (dataList) {
                    let box = [];
                    dataList.forEach((item,index) => {
                        if (item / monthMaxBox[0] * 100 < 5 && item != 0) {
                            if (item > 0) {
                                box[index] = monthMaxBox[0] * 0.05;
                            } else {
                                box[index] = Number(`-${monthMaxBox[0] * 0.05}`);
                            }
                        } else {
                            box[index] = item;
                        }
                    });
                    return box;
                };

                blueDataMax = adjustmentFunction(blueData);
                orangeDataMax = adjustmentFunction(orangeData);
                greenDataMax = adjustmentFunction(greenData);
                redDataMax = adjustmentFunction(redData);

                this.polar = {
                    title: {
                        text: ''
                    },
                    color: ['#74a0fa','#f7a945','#73deb4','#d9001b'],
                    tooltip: {
                        trigger: 'item',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: (params) => {
                            let box = params.componentIndex === 0 ? blueData : params.componentIndex === 1 ? orangeData : params.componentIndex === 2 ? greenData : redData;
                            let d = `${params.seriesName}<br>`;
                            let item = [];
                            item.push(`${box[params.dataIndex]}<br>`);
                            return d + item.join('');
                        }
                    },
                    grid: {
                        bottom: 100,
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        name: '月份',
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: false },
                    },
                    yAxis: {},
                    series: [
                        {
                            name: '发生未结算数',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: blueDataMax
                        },
                        {
                            name: '发生已结算数',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: orangeDataMax
                        },
                        {
                            name: '占用数',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: greenDataMax
                        },
                        {
                            name: '预算数',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: redDataMax
                        },
                    ],
                };
                this.$nextTick(() => {
                    this.$refs.chart.resize({
                        width: 750,
                        height: 400
                    });
                })
            }).finally(() => {
                this.budgetLoading = false;
            })
        },
    }
}
