// consumer_overproof_newDetail 【1360】新超标单详情
const __request = require(`./__request/__request_contentType_json`);
const consumer_overproof_newDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/overproof/newDetail',
        data: pParameter,
        // enable_error_alert: true // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_overproof_newDetail;

