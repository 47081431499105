import {Loading} from "element-ui";
import Q from 'q';
// 员工组件
import Transfer from "@/component/transfer/1.0.1/index.vue";
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
// 城市组件
import CitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-application-city-selector/1.0.0/index.vue";
// 机票城市
import FlightSelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
// 火车票城市
import TrainSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue";
// 酒店城市
import HotelCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue";
// 文件上传
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

// 请求部门员工
import getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
// 获取外部
import getExternalCustomerList
    from "@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerList";
// 获取用车出差规则
import consumer_web_policyConfig_getUsefulCarScene
  from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUsefulCarScene";
// 【576】根据用户的部门查询对应的成本中心
import consumer_web_costCenter_costCenterByDep
  from "@/lib/data-service/haolv-default/consumer_web_costCenter_costCenterByDep";
// 【576】获取预算险类种类
import consumer_budget_getBudgetInsurance
  from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance";
// 【576】获取预算项目种类
import consumer_budget_getBudgetPro
  from "@/lib/data-service/haolv-default/consumer_budget_getBudgetPro";
//【576】查询指定城市的出差补助信息
import consumer_budget_getSubsidyStandard
  from "@/lib/data-service/haolv-default/consumer_budget_getSubsidyStandard";
import consumer_admin_flight_generate_configServiceFee
  from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_configServiceFee";
// 成本中心
import getCostCenter from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";
// 发起出差单
import consumer_apply_addEvection from "@/lib/data-service/haolv-default/consumer_apply_addEvection";
// 检测出行人的审批人，是否面审
import consumer_approveFlow_matching from "@/lib/data-service/haolv-default/consumer_approveFlow_matching";
import {approveFlow_matching,getProcessInstanceInfo,agree} from '@/lib/data-service/haolv-default/design';
// 获取审批节点角色包含的人
import getStaff from "@/lib/data-service/haolv-default/consumer_role_getStaff";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import CostAttributionList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/cost-attribution-list/1.0.1/index.vue'
// 新审批流程组件
import WorkflowProcessLine from "@/component/workflow-process-line/index.vue";
// 【999】工作流出差申请单详情
import consumer_apply_workflowDetail from '@/lib/data-service/haolv-default/consumer_apply_workflowDetail';
import moment from "moment";
import store from '@/lib/store';
import LookForm from "../../my-apply/components/lookForm";
import FlightCitySelect from "@/component/flight-city-select/index.vue";
import EvectionCitySelect from "@/component/evection-city-select/index.vue";
import consumer_product_getBusinessOpen from '@/lib/data-service/haolv-default/consumer_product_getBusinessOpen';

export default {
    components: {
        Transfer,
        TravelerMultipleSelector,
        CitySelector,
        FlightSelector,
        TrainSelector,
        HotelCitySelector,
        CostAttributionList,
        WorkflowProcessLine,
        LookForm,
        FlightCitySelect,
        EvectionCitySelect,
    },
    data() {
        const validateSttaff = (rule, value, callback) => {
            if (value && value.length) {
                callback();
            } else {
                callback(new Error("请选择出差人员"));
            }
        };

        const validateDate = (rule, value, callback) => {
            const form = this.form;
            if (form.startDate && form.endDate) {
                callback();
            } else {
                callback(new Error("请选择出差日期"));
            }
        };

        const validateCity = (rule, value, callback) => {
            const form = this.form;
            if (form.toCityName && form.toCityId) {
                callback();
            } else {
                callback(new Error("请选择出差城市"));
            }
        };

        const validateDepartCity = (rule, value, callback) => {
            const businessType = Number(this.journeyForm.businessType);
            if (value) {
                callback();
            } else {
                if (businessType === 1) {
                    callback(new Error("请选择出发城市"));
                } else if (businessType === 2) {
                    callback(new Error("请选择出发城市"));
                } else if (businessType === 3) {
                    callback();
                }
            }
        };

        const validateToCity = (rule, value, callback) => {
            const businessType = Number(this.journeyForm.businessType);
            if (value) {
                callback();
            } else {
                if (businessType === 1) {
                    callback(new Error("请选择目的城市"));
                } else if (businessType === 2) {
                    callback(new Error("请选择目的城市"));
                } else if (businessType === 3) {
                    callback(new Error("请选择入住城市"));
                }
            }
        };

        const validateDepartTime = (rule, value, callback) => {
            const businessType = Number(this.journeyForm.businessType);
            if (value) {
                callback();
            } else {
                if (businessType === 1) {
                    callback(new Error("请选择出发日期"));
                } else if (businessType === 2) {
                    callback(new Error("请选择出发日期"));
                } else if (businessType === 3) {
                    callback(new Error("请选择入住日期"));
                }
            }
        };

        const validateReturnTime = (rule, value, callback) => {
            const businessType = Number(this.journeyForm.businessType);
            if (value) {
                callback();
            } else {
                if (businessType === 1) {
                    const flightType = Number(this.flightType);
                    if (flightType === 2) {
                        callback(new Error("请选择返程日期"));
                    } else {
                        callback();
                    }
                } else if (businessType === 2) {
                    callback();
                } else if (businessType === 3) {
                    callback(new Error("请选择离店日期"));
                }
            }
        };

        const validateCarScene = (rule, value, callback) => {
            if (this.journeyForm.carPolicyType) {
              callback();
            } else {
              callback(new Error("请选择用车场景"));
            }
        };

        const validateBudgetAmount = (rule, value, callback) => {
            if (this.journeyForm.budgetAmount === '' || this.journeyForm.budgetAmount === null || this.journeyForm.budgetAmount === undefined) {
              callback(new Error("请输入申请金额"));
            } else {
              callback();
            }
        };

        const validateBudgetService = (rule, value, callback) => {
            if (this.journeyForm.budgetService === '' || this.journeyForm.budgetService === null || this.journeyForm.budgetService === undefined) {

              callback(new Error("请输入服务费"));
            } else {
              callback();
            }
        };

        const validateJourney = (rule, value, callback) => {
            if (value.length) {
                callback();
            } else {
                callback(new Error("请添加出差行程"));
            }
        };


        const validateBudgetInsurance = (rule, value, callback) => {
            if (this.form.budgetInsuranceName || this.subsidyControlRule == 0) {
                callback();
            } else {
                callback(new Error("请选择预算险类"));
            }
        };

        const validateBudgetPro = (rule, value, callback) => {
            if (this.form.budgetProName || this.subsidyControlRule == 0) {
                callback();
            } else {
                callback(new Error("请选择项目"));
            }
        };

        const validateRecord = (rule, value, callback) => {
            if (value.length) {
                callback();
            } else {
                if (this.isFreeApprove === 0) {
                    callback(new Error("请添加审批节点"));
                } else {
                    callback();
                }
            }
        };

        return {
            type: '',
            applyNo: '',
            appType: '',
            approveStatus: '',
            processInfo: {},
            detailData: {},
            journeyVisibleEdit: false,
            serviceFee: 0,
            staffList: [],
            carSceneResults: [],   // 以设置的用车场景出差规则
            loadingInstance: null,
            loading: false,
            subsidyLoading: false,
            nodeList: [
                {text: "发起申请", value: "1", status: "2"},
                {text: "审批同意", value: "2", status: "1"},
                {text: "预定机票、火车票、酒店", value: "3", status: "1"},
                {text: "出行", value: "4", status: "1"},
            ],
            approvalNodeList: [{
                staffs: {
                    userId: 0,
                    staffName: ''
                }
            }],
            approveFlowId: '',
            approvalUserId: '',
            // 是否有审批流程
            isHasApprovalProcess: false,

            fileMaxSize: 5,
            // 是否免审 1免审，0不免审
            isFreeApprove: 0,
            // 角色审批节点
            approverNode: {},

            // 审批节点类型 1、角色，2、员工；
            // 审批角色包含人
            roleList: [],
            // 显示审批角色弹窗

            budgetInsuranceList: [],  //预算险类
            budgetProList: [],   //项目

            // 申请参数
            form: {
                staffIdList: [], //出行人员工id集合
                startDate: "", //开始时间
                endDate: "", //结束时间
                toCityName: "", //出差城市
                toCityId: "", //出差城市id
                reason: "", //出差事由
                explainDesc: "", //详细说明
                feeAffiliationName: "", //费用归属名称
                feeAffiliationId: "", //费用归属id
                feeAffiliationType: 1, //费用归属类型 1、组织架构，2、成本中心
                // accessoryUrl: "http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2020/11/09/17/72aa747f-98c9-46a7-a3e2-b59082de863b/keyboard-shortcuts-windows.jpg, http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2020/11/09/17/72aa747f-98c9-46a7-a3e2-b59082de863b/keyboard-shortcuts-windows.pdf, http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2020/11/09/17/72aa747f-98c9-46a7-a3e2-b59082de863b/keyboard-shortcuts-windows.xls, http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2020/11/09/17/72aa747f-98c9-46a7-a3e2-b59082de863b/keyboard-shortcuts-windows.doc, http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2020/11/09/17/72aa747f-98c9-46a7-a3e2-b59082de863b/keyboard-shortcuts-windows.pdf", //附件
                accessoryUrl: "",
                journeyList: [], //行程集合
                recordList: [], //审批流
                budgetItemType: 1,
                budgetInsuranceCode: "",
                budgetInsuranceName: "",
                budgetProCode: "",
                budgetProName: "",
                budgetAmount: 0,
            },

            rules: {
                staffIdList: [
                    {validator: validateSttaff, required: true, trigger: "change"},
                ],
                startDate: [
                    {validator: validateDate, required: true, trigger: "change"},
                ],
                toCityName: [
                    {validator: validateCity, required: true, trigger: "change"},
                ],
                reason: [
                    {required: true, message: "请输入出差事由", trigger: "blur"},
                ],
                feeAffiliationName: [
                    {required: true, message: "请选择费用归属部门", trigger: "change"},
                ],
                budgetInsuranceCode: [
                    {validator: validateBudgetInsurance, required: true, trigger: "change"},
                ],
                budgetProCode: [
                    {validator: validateBudgetPro, required: true, trigger: "change"},
                ],

                journeyList: [
                    {validator: validateJourney, required: true, trigger: "change"},
                ],
                recordList: [
                    {validator: validateRecord, required: true, trigger: "change"},
                ],
            },

            // 各组间暂存数据
            temporaryForm: {
                staffIdList: [],
                staffList: [],
                city: null,
                departmentId: "",
                departmentName: "",
                costCenterId: "",
                costCenterName: "",
                accessoryUrl: [],
                staffIdList2: [],
                staffList2: [],
            },

            allocation: {
                staffIdList: "",
                treeData: [],
                treeData2: [],
                treeData3: [],
                rangeDate: null,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    },
                },
                startPickerOptions: {
                    disabledDate: (time) => {
                        const moment = this.$moment;
                        const form = this.form;
                        const startDate = form.startDate;
                        const journeyForm = this.journeyForm;
                        const endDate = form.endDate;
                        if (startDate) {
                            if (journeyForm.returnTime) {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(journeyForm.returnTime)._d
                                );
                            } else {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            }
                        } else if (journeyForm.returnTime) {
                            return (
                                time.getTime() < Date.now() - 1000 * 3600 * 24 ||
                                time.getTime() > moment(journeyForm.returnTime)._d
                            );
                        } else {
                            return time.getTime() < Date.now() - 1000 * 3600 * 24;
                        }
                    },
                },
                endPickerOptions: {
                    disabledDate: (time) => {
                        const moment = this.$moment;
                        const form = this.form;
                        const journeyForm = this.journeyForm;
                        const startDate = form.startDate;
                        const endDate = form.endDate;
                        if (startDate) {
                            if (journeyForm.departTime) {
                                return (
                                    time.getTime() < moment(journeyForm.departTime)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            } else {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            }
                        } else if (journeyForm.departTime) {
                            return (
                                time.getTime() < moment(journeyForm.departTime)._d ||
                                time.getTime() > moment(endDate)._d
                            );
                        } else {
                            return time.getTime() < Date.now() - 1000 * 3600 * 24;
                        }
                    },
                },
                defaultStart: null,
                defaultEnd: null,
                departmentList1: null,
                departmentList: [],
                constCenterList: [],
                feeAffiliation: {
                    departmentList: [],
                    constCenterList: [],
                },
                feeAffiliationTypeList: [
                    {name: "1", label: "组织架构"},
                    {name: "2", label: "成本中心"},
                ],
            },
            // 出行人选择弹窗
            travelLVisible: false,
            // 费用归属选择弹窗
            feeAffiliationVisible: false,

            //费用归属部门树配置
            defaultProps: {
                children: "children",
                label: "deptName",
            },

            // 行程弹框
            journeyVisible: false,
            journeyVisibleType: 'addition',
            pickerOptionsMax: '',
            pickerOptionsMin: '',
            // 行程表单
            journeyForm: {
                code: 0,
                singleReturnType: 1,
                businessType: 1,
                carPolicyType: null,
                carPolicyTypeDescribe: null,
                carPolicyTypeText: null,
                departCityId: "",
                departCityName: "",
                departTime: "",
                returnTime: "",
                toCityId: "",
                toCityName: "",
                budgetAmount: "",    // 申请金额
                subsidyAmount: 0,    // 补助金额
                budgetService: 0,     // 服务费
                serviceAmountType: 0,     // 百分比1、定额=2
                serviceFee: 0,      // 接口返回服务费
                subsidyInfos: [],   // 出差补助信息
            },
            journeyRules: {
                departCityId: [
                    {required: true, validator: validateDepartCity, trigger: "change"},
                ],
                toCityId: [
                    {required: true, validator: validateToCity, trigger: "change"},
                ],
                departTime: [
                    {required: true, validator: validateDepartTime, trigger: "change"},
                ],
                returnTime: [{validator: validateReturnTime, trigger: "change"}],
                carScene: [{required: true, validator: validateCarScene, trigger: "change"}],
                budgetAmount: [{required: true, validator: validateBudgetAmount, trigger: "change"}],
                budgetService: [{required: true, validator: validateBudgetService, trigger: "change"}],
            },

            flightType: 1,

            flightTypeList: [
                {text: "单程", label: 1},
                {text: "往返", label: 2},
            ],

            flightFromCity: null,
            flightToCity: null,
            trainFromCity: null,
            trainToCity: null,
            hotelCityObj: null,

            oriBusinessTypes: [
                {
                    label: "机票",
                    value: 1,
                    icon: "haolv-iconfont haolv-iconflight-icon",
                },
                {
                    label: "火车票",
                    value: 2,
                    icon: "haolv-iconfont haolv-icontrain-icon",
                },
                {
                    label: "酒店",
                    value: 3,
                    icon: "haolv-iconfont haolv-iconhotel-icon",
                },
                {
                    label: "用车",
                    value: 4,
                    icon: "haolv-iconfont haolv-iconcar",
                },
            ],
            businessTypes: [],

            recordVisible: false,

            enableAutoInit: false,

            checkedKeys: [],

            bookUpperBound: 0,//最大绑定人数
            unlimitedJourney: 0,//是否不限行程 0：否 1：是
            subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
            curUser: {}, //当前登陆人
            radio: 3,


        };
    },
    filters: {
        format_TimeMonthDay(val) {
          return val ? moment(val).format("MM月DD日") : ""
        },
        format_businessType(val) {
            switch (Number(val)) {
                case 1:
                    return "机票";
                    break;
                case 2:
                    return "火车票";
                    break;
                case 3:
                    return "酒店";
                    break;
                case 4:
                    return "用车";
                    break;
            }
        },
    },
    watch: {

        "temporaryForm.staffIdList"(val) {
            if (val[0] && this.subsidyControlRule == 1) {
                consumer_web_costCenter_costCenterByDep({userId: val[0]}).then(res => {
                    if (res.datas.centerCode) {
                        this.$refs.costAttributionList._getActive({
                            centerCode: res.datas.centerCode,
                            id: res.datas.id,
                            type: 2,
                        },true);
                    }
                });
            }
        },

        "approvalUserId"(val) {
            this.approvalNodeList[0].staffs.forEach((item) => {
                if (item.userId === val) {
                    this.form.recordList = [{
                        approverUserId: item.userId,
                        approverUserName: item.staffName,
                        linkNum: 1,
                    }];
                }
            });
        },

        approverNode(val) {
            const allocation = this.allocation;
            allocation.treeData3 = [];
            if (val.relevanceId) {
                getStaff({id: val.relevanceId})
                    .then((result) => {
                        const list = result.datas;
                        let arr = [];
                        list.forEach((item) => {
                            let obj = {};
                            obj.deptId = item.deptId || 1;
                            obj.deptName = item.deptName || "默认部门";
                            obj.children = [];
                            obj.userList = [{id: item.userId, name: item.staffName}];
                            arr.push(obj);
                        });
                        allocation.treeData3 = arr;
                    })
                    .catch((err) => {
                    });
            } else {
                allocation.treeData3 = [];
            }
        },

        "temporaryForm.city"(val) {
            console.log(val);
            const form = this.form;
            form.toCityId = val.join(",");
            this.$nextTick(()=>{
                console.log(this.$refs.evectionCity)
                console.log(this.$refs.evectionCity.inputKeyText)
                form.toCityName = this.$refs.evectionCity.inputKeyText;
                this.$refs.form.validateField("toCityName");
            })

        },
        "temporaryForm.accessoryUrl"(val) {
            this.form.accessoryUrl = val.join(",");
        },
        flightFromCity(val) {
            if (val) {
                this.journeyForm.departCityId = val.code;
                this.journeyForm.departCityName = val.name;
            } else {
                this.journeyForm.departCityId = "";
                this.journeyForm.departCityName = "";
            }
        },
        flightToCity(val) {
            if (val) {
                this.journeyForm.toCityId = val.code;
                this.journeyForm.toCityName = val.name;
                if (val.name_custom_001 && this.subsidyControlRule == 1) {
                    this.getSubsidyStandard(val.code,val.name,1);
                }
            } else {
                this.journeyForm.toCityId = "";
                this.journeyForm.toCityName = "";
            }
        },
        trainFromCity(val) {
            if (val) {
                this.journeyForm.departCityId = val.stationCode;
                this.journeyForm.departCityName = val.stationName;
            } else {
                this.journeyForm.departCityId = "";
                this.journeyForm.departCityName = "";
            }
        },
        trainToCity(val) {
            if (val) {
                this.journeyForm.toCityId = val.stationCode;
                this.journeyForm.toCityName = val.stationName;
            } else {
                this.journeyForm.toCityId = "";
                this.journeyForm.toCityName = "";
            }
        },
        hotelCityObj(val) {
            if (val) {
                this.journeyForm.toCityId = val.cityId;
                this.journeyForm.toCityName = val.cityCn;
                if (val.cityId && this.subsidyControlRule == 1) {
                    this.getSubsidyStandard(val.cityId,val.cityCn,3);
                }
            } else {
                this.journeyForm.toCityId = "";
                this.journeyForm.toCityName = "";
            }
        },
    },

    methods: {
        flightCityChange(name,item){
            eval(`this.${name} = ${item}`);
        },
        clickToCity(){
            this.journeyVisibleEdit = false;
        },
        selectBudgetInsuranceCodeChange(code){
            this.budgetInsuranceList.forEach((item,index) => {
                if (item.code == code) {
                  this.form.budgetInsuranceName = item.value;
                }
            });
        },
        selectBudgetProCodeChange(code){
            this.budgetProList.forEach((item,index) => {
                if (item.code == code) {
                  this.form.budgetProName = item.value;
                }
            });
        },
        inputForceUpdate() {
            this.$forceUpdate();
        },
        placeholderText(subsidyStandard) {
            let text = '请输入补助金额';
            if (this.journeyForm.businessType == 1) {
                if (!subsidyStandard) {
                    text = '请先选择目的城市';
                }
            } else if (this.journeyForm.businessType == 3) {
                if (!this.journeyForm.returnTime || !this.journeyForm.departTime) {
                    text = '请选择入住离店日期';
                }
                if (!subsidyStandard) {
                    text = '请先选择目的城市';
                }
            }
            return text;
        },
        validateSubsidyAmount(rule, value, callback, subsidyAmount, subsidyAmountMax,subsidyStandard) {
            // if (!subsidyStandard) {
            //     callback(new Error("请选择目的城市"));
            //     return;
            // }
            let dayBox = Number(moment(this.journeyForm.returnTime).diff(moment(this.journeyForm.departTime),'days')) + 1;
            // if (this.journeyForm.businessType == 3) {
            //     if (!this.journeyForm.returnTime || !this.journeyForm.departTime) {
            //         callback(new Error("请选择入住日期和离店日期"));
            //         return;
            //     }
            // }
            if (subsidyAmount) {
                if (this.journeyForm.businessType == 3) {
                    if (subsidyAmount > Number(subsidyAmountMax)*Number(dayBox)) {
                        callback(new Error("补助金额大于补助标准"));
                        return;
                    }
                } else {
                    if (subsidyAmount > subsidyAmountMax) {
                        callback(new Error("补助金额大于补助标准"));
                        return;
                    }
                }
            } else if (subsidyAmount !== 0) {
                callback(new Error("请输入补助金额"));
                return;
            }
            callback();
        },
        getSubsidyStandard(code,name,businessType) {
            if (this.staffList != undefined && this.staffList != null && this.staffList.length != 0) {
                this.subsidyLoading = true;
            }
            this.staffList.forEach((item,index) => {
                let dataBox = {
                    cityId: code,
                    cityName: name,
                    userId: item.userId,
                    businessType: businessType,
                };
                consumer_budget_getSubsidyStandard(dataBox).then(res => {
                    item.subsidyStandard = `${name} 补助标准：${res.datas.subsidyStandard == null || res.datas.subsidyStandard == '未设置请联系管理员' ? '未设置请联系管理员' : `￥${res.datas.subsidyStandard}/天`}`;
                    if (res.datas.subsidyStandard != '未设置请联系管理员' && res.datas.subsidyStandard) {
                        if (this.journeyVisibleType == 'addition') {
                            item.subsidyAmount = Number(`${res.datas.subsidyStandard}`);
                        } else if ((item.subsidyAmount === undefined || item.subsidyAmount === 0)) {
                            item.subsidyAmount = Number(`${res.datas.subsidyStandard}`);
                        }
                        if (this.journeyVisibleType == 'edit' && this.journeyVisibleEdit == false) {
                            item.subsidyAmount = Number(`${res.datas.subsidyStandard}`);
                        }
                        item.subsidyAmountMax = res.datas.subsidyStandard;
                        if ((this.journeyVisibleType == 'edit' && this.journeyVisibleEdit == false) || this.journeyVisibleType == 'addition') {
                            if (this.journeyForm.businessType == 3 && this.journeyForm.departTime && this.journeyForm.returnTime) {
                                let startDay = this.$moment(this.journeyForm.departTime);
                                let searchDay = this.$moment(this.journeyForm.returnTime);
                                let days = Math.abs(startDay.diff(searchDay, 'days')) + 1;
                                item.subsidyAmount = Number(`${res.datas.subsidyStandard * days}`);
                            }
                        }
                    } else {
                        if (this.journeyVisibleType == 'addition') {
                            item.subsidyAmount = 0;
                        } else if ((item.subsidyAmount === undefined || item.subsidyAmount === 0)) {
                            item.subsidyAmount = 0;
                        }
                        if (this.journeyVisibleType == 'edit' && this.journeyVisibleEdit == false) {
                            item.subsidyAmount = 0;
                        }
                        item.subsidyAmountMax = 0;
                    }
                    if (index + 1 >= this.staffList.length) {
                        this.subsidyLoading = false;
                    }
                    this.$forceUpdate();
                }).finally(() => {
                    this.subsidyLoading = false;
                });
            });
        },
        // 员工数据
        req_travelList() {
            this.loading = true;
            let mentUser = (datas) => {
                const list = datas.map((data) => {
                    let obj = {};
                    obj.deptId = data.deptId;
                    obj.deptName = data.deptName;
                    obj.children = [];
                    obj.userList = [];

                    if (data.deptList && data.deptList.length) {
                        obj.children = mentUser(data.deptList);
                    }

                    if (data.staffList && data.staffList.length) {
                        obj.userList = data.staffList.map((staff) => {
                            return {id: staff.subUserId, name: staff.subStaffName};
                        });
                    }

                    return obj;
                });
                return list;
            };

            let customerList = (datas) => {
                let list = datas.map((item) => {
                    let obj = {id: item.userId, name: item.customerName};
                    return obj;
                });
                return [
                    {
                        deptId: 903,
                        deptName: "外部人员",
                        children: [],
                        userList: list,
                    },
                ];
            };
            let center = (datas) => {
                let list = datas.map((item) => {
                    let obj = {deptId: item.id, deptName: item.centerName};
                    return obj;
                })
                return list
            }
            const allocation = this.allocation;
            Promise.all([
                getDepartmentUsers(),
                getExternalCustomerList({pageSize: 100000}),
                getCostCenter(),
            ])
                .then((values) => {
                    // 部门员工
                    let list1 = mentUser(values[0].datas);
                    // 外部员工
                    let list2 = customerList(values[1].datas.list);
                    // 成本中心
                    let list3 = center(values[2].datas);
                    // 出差人员选择弹窗数据
                    allocation.treeData = [...list1, ...list2];
                    allocation.treeData2 = [...list1];
                    // 费用归属数据
                    let list4 = [...list1];
                    list4.unshift({deptId: -1, children: [], deptName: "各自所在部门"});
                    allocation.departmentList = list4;
                    // 降维
                    allocation.departmentList1 = this.flat_array(list4);
                    // 添加disable属性；
                    this.addDisable();
                    allocation.constCenterList = list3;

                    this.form.feeAffiliationName = '各自所在部门';
                    this.form.feeAffiliationId = -1;
                    this.checkedKeys = [-1]
                    this.select_department({deptId: -1, children: [], deptName: "各自所在部门"}, true)
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 数组降维
        flat_array(data) {
            const _this = this;
            let arr = [];
            data.forEach((item) => {
                let list = [];
                if (item.children && item.children.length) {
                    list = _this.flat_array(item.children);
                    arr.push(item, ...list);
                } else {
                    arr.push(item);
                }
            });
            return arr;
        },
        // 添加disable属性
        addDisable() {
            this.allocation.departmentList1.forEach((item, index) => {
                this.$set(this.allocation.departmentList1[index], "disabled", false);
            });
        },
        init() {
            this.setBusinessTypeList();
            this.getTravelPolicy();
            this.req_travelList();
            this.getAllApprovalNode();
        },

        setBusinessTypeList() {
            this.businessTypes = [];
            consumer_product_getBusinessOpen().then(res=>{
                let businessTypeList = res.datas.businessTypeList;
                this.oriBusinessTypes.forEach(value => {
                    if (businessTypeList.indexOf(value.value) > -1) {
                        this.businessTypes.push(value);
                    }
                })
            })
        },

        change_date(val) {
            const form = this.form;
            const allocation = this.allocation;
            const moment = this.$moment;
            if (val) {
                form.startDate = val[0];
                form.endDate = val[1];
                allocation.defaultStart = moment(form.startDate)._d;
                allocation.defaultEnd = moment(form.startDate)._d;
            } else {
                form.startDate = "";
                form.endDate = "";
                allocation.defaultStart = null;
                allocation.defaultEnd = null;
            }
        },

        change_startDate(val) {
            const startDate = this.form.startDate;
            const allocation = this.allocation;
            const moment = this.$moment;
            if (val) {
                allocation.defaultEnd = moment(val)._d;
            } else {
                if (startDate) {
                    allocation.defaultEnd = moment(startDate)._d;
                } else {
                    allocation.defaultEnd = null;
                }
            }
        },

        download_accessoryUrl(url) {
            window.open(url, "_blank");
        },

        timeErrorListTips(item) {
            if (this.subsidyControlRule == 0) {
              return false;
            }
            let typeBox = false;
            if (this.allocation.rangeDate) {
                let minDay = this.$moment(this.allocation.rangeDate[0]).subtract(1,'days').format('YYYY-MM-DD');
                let maxDay = this.$moment(this.allocation.rangeDate[1]).add(1,'days').format('YYYY-MM-DD');
                if (!this.$moment(item.departTime).isBetween(minDay, maxDay)) {
                    typeBox = true;
                }
                if (item.returnTime && !this.$moment(item.returnTime).isBetween(minDay, maxDay)) {
                    typeBox = true;
                }
            }
            return typeBox;
        },

        staffIdErrorListTips(item) {
            if (this.subsidyControlRule == 0) {
              return false;
            }
            let nameList = this.allocation.staffIdList.split(',');
            let typeBox = [];
            item.subsidyInfos.forEach((SItem,SIndex) => {
                typeBox.push(nameList.indexOf(SItem.userName) != -1);
            });

            if (nameList.length != item.subsidyInfos.length) {
                typeBox.push(false);
            }
            return typeBox.indexOf(false) == -1 ? false : true;
        },

        // 弹出员工选择框
        popup_travelList() {
            const __this = this;
            __this.temporaryForm.staffIdList = __this.form.staffIdList;
            __this.$refs.travelerMultipleSelector.show();
        },
        // 关闭员工选择框
        // close_travelList() {
        //     this.travelLVisible = false;
        //     this.temporaryForm.staffIdList = this.form.staffIdList;
        // },
        // 确定选择员工
        // commit_travelList() {
        //     this.travelLVisible = false;
        //     const form = this.form;
        //     form.staffIdList = this.temporaryForm.staffIdList;
        //
        //     let sttaffIdList = [];
        //     this.temporaryForm.staffList.forEach((item) => {
        //         if (form.staffIdList.includes(item.id)) sttaffIdList.push(item.name);
        //     });
        //     this.allocation.staffIdList = sttaffIdList.join("，");
        // },

        // 选择员工回调
        get_select_sttaff(val) {
            this.temporaryForm.staffList = val;
        },

        // 弹出费用归属选择
        popup_feeAffiliation() {
            this.feeAffiliationVisible = true;
        },

        // 费用归属当选择某一部门时
        select_department(data, idSelect) {
            const temporaryForm = this.temporaryForm;
            if (idSelect) {
                const deptId = data.deptId;
                this.allocation.departmentList1.forEach((item, index) => {
                    if (item.deptId === deptId) {
                        this.$set(
                            this.allocation.departmentList1[index],
                            "disabled",
                            false
                        );
                    } else {
                        this.$set(this.allocation.departmentList1[index], "disabled", true);
                    }
                });
                temporaryForm.departmentName = data.deptName;
                temporaryForm.departmentId = data.deptId;
            } else {
                this.addDisable();
                temporaryForm.departmentName = "";
                temporaryForm.departmentId = "";
            }
        },
        //费用归属选择成本中心
        change_costCenter(val) {
            const temporaryForm = this.temporaryForm;
            const constCenterList = this.allocation.constCenterList;
            for (const i in constCenterList) {
                const item = constCenterList[i];
                if (item.deptId === val) temporaryForm.costCenterName = item.deptName;
            }
        },

        // 关闭费用归属选择
        close_feeAffiliation() {
            this.feeAffiliationVisible = false;
        },
        // 提交选择费用归属
        commit_feeAffiliation() {
            this.feeAffiliationVisible = false;
            const form = this.form;
            const feeAffiliationType = Number(form.feeAffiliationType);
            const temporaryForm = this.temporaryForm;
            if (feeAffiliationType === 1) {
                form.feeAffiliationName = temporaryForm.departmentName;
                form.feeAffiliationId = temporaryForm.departmentId;
            } else if (feeAffiliationType === 2) {
                form.feeAffiliationName = temporaryForm.costCenterName;
                form.feeAffiliationId = temporaryForm.costCenterId;
            }
        },

        // 选择一个文件时
        change_file(file) {
            const size = file.size / 1024 / 1024;
            if (size > this.fileMaxSize) {
                return this.$message.error("文件大小不能超过5M");
            }
            const _this = this;
            const aOssClient = new OssClient();
            aOssClient
                .multipartUpload({
                    file: file.raw,
                    progress: function* (p) {
                    },
                })
                .then(function (data) {
                    _this.$message.success("文件上传成功");
                    _this.temporaryForm.accessoryUrl.push(data.url);
                });
        },

        // 删除选中文件
        delet_file(index) {
            this.temporaryForm.accessoryUrl.splice(index, 1);
        },
        journeyFormTimeBlur() {
            if (this.journeyForm.departTime && this.journeyForm.returnTime) {
                let startDay = this.$moment(this.journeyForm.departTime);
                let searchDay = this.$moment(this.journeyForm.returnTime);
                let days = Math.abs(startDay.diff(searchDay, 'days')) + 1;
                this.staffList.forEach((item,index) => {
                    item.subsidyAmount = Number(`${item.subsidyAmountMax * days}`);
                });
                this.$forceUpdate();
                if (this.journeyForm.serviceAmountType == 2) {
                    this.journeyForm.serviceAmount = (Number(this.serviceFee) * this.staffList.length * days).toFixed(2);
                }
            };
            this.$refs.journeyForm.validate();
        },
        // 切换行程业务方式
        change_businessType(val) {
            this.journeyForm.businessType = val;
            this.journeyForm.singleReturnType = this.flightType;
            if (this.journeyForm.businessType != 1) {
               this.journeyForm.singleReturnType = null;
            }
            this.reset_journeyForm(1);
            this.subsidyLoading = true;
            this.staffList.forEach((item) => {
                item.subsidyAmount = '';
                item.subsidyAmountMax = '';
                item.subsidyStandard = '';
            });
            let dataBox = {
                companyId: store.state.userInfo.companyId,
                evectionType: 2,
                businessType: this.journeyForm.businessType,
            };
            if (this.subsidyControlRule == 1) {
                consumer_admin_flight_generate_configServiceFee(dataBox).then((res) => {
                    if (res.datas) {
                        this.journeyForm.serviceAmountType = res.datas.serviceAmountType;
                        // this.journeyForm.serviceAmountType = 1;
                        this.serviceFee = res.datas.serviceFee;
                        if (this.journeyForm.serviceAmountType == 2) {
                            this.journeyForm.serviceAmount = (Number(res.datas.serviceFee) * this.staffList.length).toFixed(2);
                        } else {
                            this.journeyForm.serviceFee = res.datas.serviceFee;
                        }
                        this.$forceUpdate();
                    }
                }).finally(() => {
                    this.subsidyLoading = false;
                });
            } else {
                this.subsidyLoading = false;
            }
        },

        // 清除行程表单
        reset_journeyForm(type) {
            // type: 1、切换业务类型，2、提交或关闭后重置
            const form = this.journeyForm;
            const noreset = type === 1 ? ["businessType", "departTime"] : ["businessType"];
            let code = this.journeyForm.code;
            for (const key in form) {
                if (!noreset.includes(key)) form[key] = "";
            }

            if (type === 1) {
            } else if (type === 2) {
                form.businessType === "1";
            }

            this.flightFromCity = null;
            this.flightToCity = null;
            this.trainFromCity = null;
            this.trainToCity = null;
            this.hotelCityObj = null;
            this.journeyForm.carPolicyType = null;
            this.journeyForm.carPolicyTypeDescribe = null;
            this.journeyForm.carPolicyTypeText = null;
            this.journeyForm.departTime = '';
            this.journeyForm.returnTime = '';
            this.journeyForm.code = code;
            const _this = this;
            setTimeout(() => {
                _this.$refs.journeyForm1.clearValidate();
                _this.$refs.journeyForm2.clearValidate();
                _this.$refs.journeyForm3.clearValidate();
                _this.$refs.journeyForm4.clearValidate();
            }, 0);
        },
        // 是否往返机票
        change_flightType(val) {
            const form = this.journeyForm;
            if (val === 1) {
                form.returnTime = "";
                this.$refs.returnTime.clearValidate();
            } else if (val === 2) {
            }
        },
        // 用车场景选中
        carSceneResultsClick(carPolicyType,item){
            if (carPolicyType == this.journeyForm.carPolicyType) {
                this.journeyForm.carPolicyType = null;
                this.journeyForm.carPolicyTypeDescribe = null;
                this.journeyForm.carPolicyTypeText = null;
            } else {
              this.journeyForm.carPolicyType = carPolicyType;
              this.journeyForm.carPolicyTypeDescribe = item.carPolicyTypeDescribe;
              this.journeyForm.carPolicyTypeText = item.carPolicyTypeText;
            }
            this.$refs.journeyForm.clearValidate();
            this.$forceUpdate();
        },
        proportionInput() {
            if (this.journeyForm.serviceAmountType == 1) {
                this.journeyForm.serviceAmount = (this.journeyForm.budgetAmount * (this.journeyForm.serviceFee * 0.01)).toFixed(2);
            }
        },
        // 编辑
        clickJourneyList(item) {
            this.journeyVisibleEdit = true;
            if (item.businessType == 1) {
                this.flightToCity = {
                    "name": item.subsidyInfos[0].cityName,
                    "code": item.subsidyInfos[0].cityCode,
                    "cityName": item.subsidyInfos[0].cityName,
                    "cityCode": item.subsidyInfos[0].cityCode,
                };
                this.flightFromCity = {
                    "name": item.subsidyInfos[0].cityNameFrom,
                    "code": item.subsidyInfos[0].cityCodeFrom,
                    "cityName": item.subsidyInfos[0].cityNameFrom,
                    "cityCode": item.subsidyInfos[0].cityCodeFrom,
                };
            } else if (item.businessType == 3) {
                this.hotelCityObj = {
                    "cityCn": item.toCityName,
                    "cityId": item.toCityId,
                };
                this.journeyForm.returnTime = item.returnTime;
            }

            this.journeyForm.businessType = item.businessType;
            this.journeyForm.budgetAmount = item.budgetAmount;
            this.journeyForm.departTime = item.departTime;
            this.journeyForm.serviceAmount = item.serviceAmount;
            this.journeyForm.serviceAmountType = item.serviceAmountType;
            this.journeyForm.code = item.code;
            this.journeyVisibleType = 'edit';
            this.staffList.forEach((SItem,SIndex) => {
                item.subsidyInfos.forEach((TItem,TIndex) => {
                    if (TItem.userName == SItem.name) {
                        SItem.subsidyAmount = TItem.subsidyAmount;
                    }
                })
            });
            this.journeyVisible = true;
            setTimeout(() => {
                this.$refs.journeyForm1.clearValidate();
                this.$refs.journeyForm2.clearValidate();
                this.$refs.journeyForm3.clearValidate();
                this.$refs.journeyForm4.clearValidate();
            },100)
        },
        // 弹窗行程选择弹框
        popup_journey() {
            // 先判断出差人员
            if (this.allocation.staffIdList == '' && this.subsidyControlRule == 1) {
                this.$message({
                    message: '请先选择出差人员',
                    type: 'warning'
                });
                return
            }
            // 先判断出差日期选择了没有
            if ((this.allocation.rangeDate === null || this.allocation.rangeDate === '') && this.subsidyControlRule == 0) {
                this.$message({
                    message: '请先填写出差日期,填写了出差日期才可以添加行程',
                    type: 'warning'
                });
                return
            }
            this.staffList = this.$refs.travelerMultipleSelector.entity_list_for_v_model;
            this.staffList.forEach((item,index) => {
                item.subsidyAmount = '';
                item.subsidyAmountMax = '';
                item.subsidyStandard = '';
            });
            this.journeyForm.businessType = 1;
            this.journeyVisible = true;
            this.$nextTick(() => {
                this.$refs.journeyForm1.clearValidate();
                this.$refs.journeyForm2.clearValidate();
                this.$refs.journeyForm3.clearValidate();
                this.$refs.journeyForm4.clearValidate();
            });
            this.journeyVisibleType = 'addition';
            this.journeyForm.code = this.form.journeyList.length + 1;
            this.flightType = 1;
            if (this.subsidyControlRule == 1) {
                let dataBox = {
                    companyId: store.state.userInfo.companyId,
                    evectionType: 2,
                    businessType: this.journeyForm.businessType,
                };
                consumer_admin_flight_generate_configServiceFee(dataBox).then((res) => {
                    this.journeyForm.serviceAmountType = res.datas.serviceAmountType;
                    // this.journeyForm.serviceAmountType = 1;
                    if (this.journeyForm.serviceAmountType == 2) {
                        this.journeyForm.serviceAmount = (Number(res.datas.serviceFee) * this.staffList.length).toFixed(2);
                    } else {
                        this.journeyForm.serviceFee = res.datas.serviceFee;
                    }
                    this.$forceUpdate();
                });
            }
            consumer_web_policyConfig_getUsefulCarScene().then(res => {
              this.carSceneResults = res.datas.carSceneResults;
              // this.carSceneResults = [
              //   {carPolicyType:1,carPolicyTypeText:'出差用车'},
              //   {carPolicyType:2,carPolicyTypeText:'加班用车'},
              //   {carPolicyType:3,carPolicyTypeText:'接送服务'},
              //   {carPolicyType:4,carPolicyTypeText:'代叫服务'},
              //   {carPolicyType:5,carPolicyTypeText:'定点通勤'},
              //   {carPolicyType:6,carPolicyTypeText:'外勤用车'},
              // ];
              this.carSceneResults.forEach((item,index) => {
                switch (item.carPolicyType) {
                  case 1:
                    // 出差用车
                    item.carPolicyTypeDescribe = '适用于员工异地出差期间用车';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_trave_vehicles fail-icon';
                    item.iconColor = '#ffa64e';
                    break;
                  case 2:
                    // 加班用车
                    item.carPolicyTypeDescribe = '适用于工作日/节假日加班';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_overtime_car fail-icon';
                    item.iconColor = '#a06fe1';
                    break;
                  case 3:
                    // 接送服务
                    item.carPolicyTypeDescribe = '适用于差旅场景及接送会员等枢纽类接送如接送机场、接送火车站';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_shuttle_service fail-icon';
                    item.iconColor = '#ff740f';
                    break;
                  case 4:
                    // 代叫用车
                    item.carPolicyTypeDescribe = '适用于会议用车场景、营销场景，可支持为领导、高管、客户、参会人员等角色代叫车';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_car_hailing fail-icon';
                    item.iconColor = '#4e9cff';
                    break;
                  case 5:
                    // 定点通勤
                    item.carPolicyTypeDescribe = '适用于多个办公地点之间往返通勤';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_fixed_point fail-icon';
                    item.iconColor = '#90c26b';
                    break;
                  case 6:
                    // 外勤用车
                    item.carPolicyTypeDescribe = '适用于日常公务外勤，如拜访客户、高管出行等';
                    item.iconText = 'haolv-iconfont haolv-iconpolicy_field_vehicles fail-icon';
                    item.iconColor = '#4dc4fa';
                    break;
                }
              });
            });
        },
        // 关闭行程弹窗
        close_journey() {
            this.journeyVisible = false;
            this.reset_journeyForm();
        },
        checkSubsidy() {
            return new Promise(resolve => {
                if ((this.journeyForm.businessType === 1 || this.journeyForm.businessType === 3) && this.subsidyControlRule === 1){
                    this.$refs.journeyForm5.validate((valid)=>{
                        resolve(valid);
                    })
                } else {
                    resolve(true)
                }
            })
        },
        // 确认行程
        async commit_journey() {
            let sRes = await this.checkSubsidy();
            let journeyForm;
            switch (this.journeyForm.businessType) {
                case 1:
                    journeyForm = this.$refs.journeyForm1;
                    break
                case 2:
                    journeyForm = this.$refs.journeyForm2;
                    break
                case 3:
                    journeyForm = this.$refs.journeyForm3;
                    break
                case 4:
                    journeyForm = this.$refs.journeyForm4;
                    break
            }
            journeyForm.validate((valid) => {
                if (valid) {
                    this.journeyForm.singleReturnType = this.flightType;
                    let subsidyAmount = 0;
                    let subsidyInfos = [];
                    this.staffList.forEach((item) => {
                        subsidyAmount = Number(item.subsidyAmount) + subsidyAmount;
                        subsidyInfos.push({
                            cityCode: this.journeyForm.businessType == 1 ? this.flightToCity.code : this.journeyForm.businessType == 3 ? this.hotelCityObj.cityId : '',
                            cityName: this.journeyForm.businessType == 1 ? this.flightToCity.name : this.journeyForm.businessType == 3 ? this.hotelCityObj.cityName : '',
                            cityCodeFrom: this.journeyForm.businessType == 1 ? this.flightFromCity.code : '',
                            cityNameFrom: this.journeyForm.businessType == 1 ? this.flightFromCity.name : '',
                            subsidyAmount: item.subsidyAmount,
                            subsidyStandard: item.subsidyAmountMax,
                            userId: item.userId,
                            userName: item.name,
                        });
                    });
                    this.journeyForm.subsidyAmount = subsidyAmount;
                    this.journeyVisible = false;
                    let journeyForm = this.journeyForm;
                    this.journeyForm.subsidyInfos = subsidyInfos;
                    delete journeyForm.carPolicyTypeDescribe;
                    if (this.flightFromCity != null && (this.flightFromCity['areaType'] == 2 || this.flightToCity['areaType'] == 2)) {
                        journeyForm['areaType'] = 2;
                    } else {
                        journeyForm['areaType'] = 1;
                    }
                    if (this.journeyVisibleType == 'edit') {
                        this.form.journeyList.forEach((JItem,JIndex) => {
                            if (JItem.code == this.journeyForm.code) {
                                this.form.journeyList[JIndex] = {...journeyForm};
                            }
                        });
                    } else {
                        this.form.journeyList.push({...journeyForm});
                    }
                    this.form.budgetAmount = 0;
                    this.form.journeyList.forEach((JItem,JIndex) => {
                      this.form.budgetAmount = Number(JItem.budgetAmount) + Number(JItem.serviceAmount) + Number(JItem.subsidyAmount) + this.form.budgetAmount;
                    });
                    // this.$refs.form.validateField(["journeyList"]);
                    this.reset_journeyForm();
                } else {
                    return false;
                }
            });
        },

        updateTravelTime() {
            let timeBox = [];
            this.form.journeyList.forEach((item) => {
                timeBox.push(item.departTime);
                if (item.returnTime) {
                    timeBox.push(item.returnTime);
                }
            });
            timeBox = timeBox.sort((a,b) => {
              let startDay = this.$moment(a)
              let searchDay = this.$moment(b)
              return searchDay.diff(startDay, 'days')
            });
            this.pickerOptionsMax = timeBox[0];
            this.pickerOptionsMin = timeBox[timeBox.length - 1];
        },

        // 删除点击行程
        delet_journey(index) {
            this.form.journeyList.splice(index, 1);
            this.form.journeyList.forEach((item,IIndex) => {
                item.code = IIndex + 1;
            });
            this.form.budgetAmount = 0;
            this.form.journeyList.forEach((JItem,JIndex) => {
                this.form.budgetAmount = Number(JItem.budgetAmount) + Number(JItem.serviceAmount) + Number(JItem.subsidyAmount) + this.form.budgetAmount;
            });
        },
        // 删除点击审批节点
        delet_node_item(index) {
            this.form.recordList.splice(index, 1);
            this.temporaryForm.staffIdList2 = JSON.parse(JSON.stringify(this.form.recordList))
        },

        turn_city(type) {
            if (type === 1) {
                const fromCity = {...this.flightFromCity};
                const toCity = {...this.flightToCity};
                this.flightFromCity = toCity;
                this.flightToCity = fromCity;
            } else if (type === 2) {
                const fromCity = {...this.trainFromCity};
                const toCity = {...this.trainToCity};
                this.trainFromCity = toCity;
                this.trainToCity = fromCity;
            }
        },

        // 弹出选择节点审批人
        popup_node() {
            const __this = this;
            __this.$refs.approvalMultipleSelector.show();
        },
        //
        close_node() {
            this.recordVisible = false;
            this.form.recordList;
        },

        commit_node() {
            this.recordVisible = false;
            const temporaryForm = this.temporaryForm;
            const staffList2 = temporaryForm.staffList2;
            const form = this.form;
            if (staffList2.length) {
                const {id, name} = staffList2[0];
                if (staffList2.length > 1) {
                    this.$message.warning("只能选择一位审批人");
                }
                form.recordList = [
                    {
                        approverUserId: id,
                        approverUserName: name,
                        linkNum: 1,
                    },
                ];
            } else {
                form.recordList = [];
            }
            temporaryForm.staffList2 = [];
            temporaryForm.staffIdList2 = [];
            this.$refs.form.validateField(["recordList"]);
        },

        get_node_sttaff(val) {
            this.temporaryForm.staffList2 = val;
        },

        popup_select_role_staff() {
            const recordList = this.form.recordList;
            if (recordList.length) {
                this.temporaryForm.staffIdList2 = [recordList[0].approverUserId];
            }
            this.recordVisible = true;
        },

        // 提交申请
        commit() {
            const __this = this;
            let staffIdErrorListTipsSwitch = true;
            __this.updateTravelTime();
            if (this.form.journeyList.length == 0) {
                this.$message({
                    message: '请先添加出差行程',
                    type: 'warning'
                });
                return;
            }
            if (this.subsidyControlRule == 1) {
                __this.form.journeyList.forEach((item,index) => {
                    if (this.staffIdErrorListTips(item)) {
                        this.$message({
                          message: '出差人员发生变动，请编辑出差行程',
                          type: 'warning'
                        });
                        staffIdErrorListTipsSwitch = false;
                        return;
                    }
                });
            }


            // 行程日期和出差日期判断
            if (this.allocation.rangeDate && this.subsidyControlRule == 1) {
                let minDay = this.$moment(this.allocation.rangeDate[0]).subtract(1,'days').format('YYYY-MM-DD');
                let maxDay = this.$moment(this.allocation.rangeDate[1]).add(1,'days').format('YYYY-MM-DD');
                if (!this.$moment(this.pickerOptionsMax).isBetween(minDay, maxDay) || !this.$moment(this.pickerOptionsMin).isBetween(minDay, maxDay)) {
                    this.$message({
                        message: '行程日期与出差日期冲突，请重新选择行程日期',
                        type: 'warning'
                    });
                    return;
                }
            }

            if (staffIdErrorListTipsSwitch == false) {
                return;
            }

            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loadingInstance = Loading.service({
                        target: "body",
                        text: "请求中...",
                    });
                    let form = JSON.parse(JSON.stringify(this.form))
                    form.approveFlowId = this.approveFlowId
                    form.journeyList.forEach((JItem,JIndex) => {
                        JItem.budgetSum = Number(JItem.budgetAmount) + Number(JItem.serviceAmount);
                    });
                    if (this.detailData && this.detailData.approveApply) {
                      form.taskId = this.detailData.approveApply.taskId;
                    }
                    if (this.applyNo) {
                      form.processInstanceId = this.applyNo;
                    }
                    consumer_apply_addEvection(form)
                        .then((res) => {
                            if (this.type == 'exceed') {
                                let formValue = {
                                    'comments': "",
                                    'imageList': [],
                                    'fileList': [],
                                    'signInfo': "",
                                };
                                let params = {
                                    ...this.processInfo,
                                    ...formValue,
                                    'attachments': [],
                                };
                                try {
                                    agree(params).then(resultInfo=>{
                                        this.loadingInstance.close();
                                        this.$message.success("提交出差单成功");
                                        this.$router.push({
                                            name: "admin-my-apply-manage-apply",
                                        });
                                    }).finally(() => {
                                        this.loadingInstance.close();
                                    });
                                } catch (e) {
                                    this.loadingInstance.close();
                                }
                            } else {
                                this.loadingInstance.close();
                                this.$message.success("发起出差单成功");
                                this.$router.push({
                                    name: "admin-my-apply-manage-apply",
                                });
                            }
                        })
                        .catch((err) => {
                            this.loadingInstance.close();
                        });
                } else {
                    return false;
                }
            });
        },

        // 取消申请
        cancel() {
            this.$router.back();
        },

        async getAllApprovalNode() {
            await this.$store.state.workflowDefer.promise;
            await this.$store.state.userInfoDefer.promise;
            if (this.$store.state.workflow === '1') {
                let res = await approveFlow_matching({companyId: this.$store.state.userInfo.companyId, userId: this.$store.state.userInfo.id, groupId: 1});
                let form = res.result;
                this.$refs.workflowProcessLine.init(form);
            } else {
                let res = await consumer_approveFlow_matching({applyType: 1, staffIds: []});
                const datas = res.datas;
                const form = this.form;
                const isFreeApprove = Number(datas.isFreeApprove);
                this.isFreeApprove = isFreeApprove;
                this.isHasApprovalProcess = isFreeApprove === 0 && datas.nodeList && datas.nodeList.length !== 0
                if (this.isHasApprovalProcess) {
                    this.approvalNodeList = datas.nodeList;
                    this.approvalUserId = this.approvalNodeList[0].staffs[0].userId;
                    this.approveFlowId = this.approvalNodeList[0].approveFlowId
                } else {
                    this.approvalNodeList = [];
                    form.recordList = [];
                }
            }

        },


        //获取出差规则
        async getTravelPolicy() {
            // this.subsidyControlRule = 0;
            if (this.subsidyControlRule == 1) {
                this.businessTypes = this.businessTypes.filter(item => item.value !== 2 && item.value !== 4);
                consumer_budget_getBudgetInsurance().then(res => {
                    this.budgetInsuranceList = res.datas;
                });
                consumer_budget_getBudgetPro().then(res => {
                    this.budgetProList = res.datas;
                });
            }
            if(this.bookUpperBound === 1) {
                await this.getCurUser();
                this.form.staffIdList = [this.curUser.id];
                this.allocation.staffIdList = this.curUser.realName;
                this.temporaryForm.staffIdList = [this.curUser.id];
                this.$refs.travelerMultipleSelector.entity_list_for_v_model.push({ "userId": this.curUser.id, "name": this.curUser.realName,});
            }
            this.$refs.costAttributionList.init({
                type: '002',
                showDept: this.subsidyControlRule == 1 ? false : true,
            })
        },

        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
        },

        showNotChangeTips() {
            this.$message.warning('最大帮订人数为1时，出差人员不允许修改！');
        },
        subtotalFunction(data) {
          let num = 0;
          if (data) {
            let budgetAmount = data.budgetAmount ? Number(`${data.budgetAmount}`) : 0;
            let serviceAmount = data.serviceAmount ? Number(`${data.serviceAmount}`) : 0;
            let subsidyAmount = data.subsidyAmount ? Number(`${data.subsidyAmount}`) : 0;
            num = num + budgetAmount + serviceAmount + subsidyAmount;
          } else {
            if (this.staffList) {
              this.staffList.forEach((item,index) => {
                num = num + Number(`${item.subsidyAmount}`);
              })
            }
            num = num + Number(`${this.journeyForm.budgetAmount}`) + Number(`${this.journeyForm.serviceAmount}`);
          }
          return isNaN(num) ? 0 : num.toFixed(2);
        },
    },
    async mounted() {
        await this.$store.state.policyDefer.promise;
        await this.$store.state.userInfoDefer.promise;
        this.bookUpperBound = store.state.policy.bookUpperBound;
        this.unlimitedJourney = store.state.policy.unlimitedJourney;
        this.subsidyControlRule = store.state.policy.subsidyControlRule;
        this.init();
        this.type = this.$route.query.type;
        this.applyNo = this.$route.query.applyNo;
        this.appType = this.$route.query.appType ? this.$route.query.appType : 2;
        this.approveStatus = this.$route.query.approveStatus ? this.$route.query.approveStatus : 1;
        if (this.type == 'exceed') {
            consumer_apply_workflowDetail({applyNo: this.applyNo,appType: this.appType,approveStatus: this.approveStatus}).then(res => {
                this.detailData = res.datas;
                this.processInfo = {
                    applyNo: this.applyNo,
                    taskId: this.detailData.approveApply.taskId,
                    signFlag: null,
                    currentUserInfo: {
                        id: this.$store.state.userInfo.id,
                        name: this.$store.state.userInfo.realName,
                    },
                };
                getProcessInstanceInfo(this.applyNo, this.detailData.approveApply.taskId).then(resultInfo=>{
                    let formData = {...resultInfo['result']['formData']};
                    this.processInfo.formData = formData;
                });
                // 出差人员
                this.form.staffIdList = [];
                this.allocation.staffIdList = null;
                res.datas.staffDetailVos.forEach((item,index) => {
                    this.form.staffIdList.push(item.userId);
                    this.$refs.travelerMultipleSelector.entity_list_for_v_model.push({ "userId": item.userId, "name": item.staffName, });
                    this.staffList = this.$refs.travelerMultipleSelector.entity_list_for_v_model;
                    if (this.allocation.staffIdList == null) {
                        this.allocation.staffIdList = `${item.staffName}`;
                    } else {
                        this.allocation.staffIdList = `${this.allocation.staffIdList},${item.staffName}`;
                    }
                });
                // 出差人员--end

                // 出差日期
                this.allocation.rangeDate = [];
                this.allocation.rangeDate = [res.datas.evection.startDate,res.datas.evection.endDate];
                this.change_date(this.allocation.rangeDate);
                // 出差日期--end

                // 出差城市
                // this.$refs.evectionCity.$refs.aBaseMultipleEntitySelector.input = res.datas.evection.toCityName;
                this.form.toCityName = res.datas.evection.toCityName;
                this.form.toCityId = res.datas.evection.toCityId;
                let box = [];
                res.datas.evection.toCityId.split(',').forEach((item,index) => {
                    box.push(Number(`${item}`));
                });
                this.temporaryForm.city = box;
                // 出差城市--end

                // 详情说明、出差事由
                this.form.reason = res.datas.evection.reason;
                this.form.explainDesc = res.datas.evection.explainDesc;
                // 详情说明、出差事由--end

                // 成本归属
                // res.datas.evection.feeAffiliationType = 2;
                // res.datas.evection.feeAffiliationName = '大数据中心';
                // res.datas.evection.feeAffiliationId = 78;
                this.form.feeAffiliationType = res.datas.evection.feeAffiliationType;
                this.form.feeAffiliationName = res.datas.evection.feeAffiliationName;
                this.form.feeAffiliationId = res.datas.evection.feeAffiliationId;
                setTimeout(() => {
                    this.$refs.costAttributionList._getActive({
                        centerCode: '',
                        id: res.datas.evection.feeAffiliationId,
                        type: res.datas.evection.feeAffiliationType,
                    },true);
                },500);
                // 成本归属--end

                // 附件
                // res.datas.evection.accessoryUrl = 'http://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2022/02/14/11/ce325df5-f59c-495c-bc06-091dcb2d6b8a/铨成商旅logo.png';
                this.form.accessoryUrl = res.datas.evection.accessoryUrl;
                // 附件--end

                let journeyListBox = [];
                res.datas.journeyList.forEach((item,index) => {
                    consumer_admin_flight_generate_configServiceFee({
                        companyId: res.datas.evection.companyId,
                        evectionType: 2,
                        businessType: item.businessType,
                    }).then(FRes => {
                        let subsidyInfosList = [];
                        let cityCode = item.toCityId;
                        let cityName = item.toCityName;
                        let cityCodeFrom = item.departCityId;
                        let cityNameFrom = item.departCityName;
                        res.datas.staffDetailVos.forEach((SItem,SIndex) => {
                            subsidyInfosList.push({
                                "cityCode": cityCode,
                                "cityName": cityName,
                                "cityCodeFrom": cityCodeFrom,
                                "cityNameFrom": cityNameFrom,
                                "subsidyAmount": item.subsidyAmount,
                                "subsidyStandard": item.subsidyStandard,
                                "userId": SItem.userId,
                                "userName": SItem.staffName,
                            });
                        });
                        journeyListBox.push({
                            "code": index + 1,
                            "singleReturnType": item.singleReturnType,
                            "businessType": item.businessType,
                            "carPolicyType": item.carPolicyType,
                            "carPolicyTypeText": null,
                            "departCityId": item.departCityId,
                            "departCityName": item.departCityName,
                            "departTime": item.departTime,
                            "returnTime": item.returnTime,
                            "toCityId": item.toCityId,
                            "toCityName": item.toCityName,
                            "budgetAmount": item.budgetAmount,   // 申请金额
                            "subsidyAmount": Number(`${item.subsidyAmount}`),   // 补助金额
                            "budgetService": '',  // 服务费
                            "serviceAmountType": FRes.datas.serviceAmountType,   // 百分比1、定额=2
                            "serviceFee": FRes.datas.serviceFee,  // 接口返回服务费
                            "subsidyInfos": subsidyInfosList,
                            "serviceAmount": item.serviceAmount,
                        })
                    });
                });
                this.form.budgetAmount = res.datas.evection.budgetAmount;
                this.form.journeyList = journeyListBox;


                if (this.subsidyControlRule == 1) {
                    this.form.budgetItemType = res.datas.evection.budgetItemType ? res.datas.evection.budgetItemType : 1;
                    this.form.budgetInsuranceCode = res.datas.evection.budgetInsuranceCode;
                    this.form.budgetInsuranceName = res.datas.evection.budgetInsuranceName;
                    this.form.budgetItemTypeName = res.datas.evection.budgetItemTypeName;
                    this.form.budgetProCode = res.datas.evection.budgetProCode;
                    this.form.budgetProName = res.datas.evection.budgetProName;
                }
            }).finally(() => {
                this.$refs.lookForm.getFlow();
                this.$refs.lookForm.hideFillRegion();
            });
        }

        const __this = this;
        __this.$refs.travelerMultipleSelector.init_parameter.btn_ok_click_event_handler = function (args) {
            if(args.selected_id_list &&　__this.bookUpperBound < args.selected_id_list.length){
                __this.$message.warning('不能超过最大帮订人数，最大帮订人数为'+__this.bookUpperBound+'。');
            }
            __this.form.staffIdList = [];
            args.selected_id_list.forEach((item, index) => {
                if(index < __this.bookUpperBound){
                    __this.form.staffIdList.push(item);
                }
            })
            let staffIdList = ''
            args.selected_entity_list.forEach((item, index) => {
                if(index < __this.bookUpperBound) {
                    staffIdList += item.name + ','
                }
            })
            staffIdList = staffIdList ? staffIdList.substring(0, staffIdList.lastIndexOf(',')) : ''
            __this.allocation.staffIdList = staffIdList
        };

        __this.$refs.approvalMultipleSelector.init_parameter.btn_ok_click_event_handler = function (args) {
            console.log('args', args)
            __this.temporaryForm.staffList2 = args.selected_entity_list
            const temporaryForm = __this.temporaryForm;
            const staffList2 = temporaryForm.staffList2;
            const form = __this.form;
            if (staffList2.length) {
                const {userId, name} = staffList2[0];
                if (staffList2.length > 1) {
                    __this.$message.warning("只能选择一位审批人");
                }
                form.recordList = [
                    {
                        approverUserId: userId,
                        approverUserName: name,
                        linkNum: 1,
                    },
                ];
                temporaryForm.staffList2 = [];
                temporaryForm.staffIdList2 = [];
                temporaryForm.staffList2.push(staffList2[0]);
                temporaryForm.staffIdList2.push(userId);
            } else {
                form.recordList = [];
            }
            __this.$refs.form.validateField(["recordList"]);
        };


        __this.$refs.travelerMultipleSelector.init_parameter.get_data_source_parameter = function () {
            const d = {
                companyId: '',
                isSelectCustomer: true,
            };
            return Q.resolve(d);
        };

        __this.$refs.travelerMultipleSelector.init({
            situation: `100`,
        });

        __this.$refs.approvalMultipleSelector.init_parameter.get_data_source_parameter = function () {
            const d = {
                companyId: '',
                isSelectCustomer: false,
            };
            return Q.resolve(d);
        };
        __this.$refs.approvalMultipleSelector.init({
            dialog: {
                title: `请选择下级审批人`,
            },
            select_mode: `single`,
        });


    },
};
