//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 品牌列表
import consumer_hotel_search_getHotelBrandList
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelBrandList.js";
import consumer_hotel_search_filterItem from '@/lib/data-service/haolv-default/consumer_hotel_search_filterItem';
import consumer_hotel_common_cityBrand from '@/lib/data-service/haolv-default/consumer_hotel_common_cityBrand';
import HotelAreaSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-area-selector/1.0.1/index.vue'
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";

export default {
    props: {
        cityId: {
            type: String,
            default: '70082',
        },
        cityName: {
            type: String,
            default: '',
        },
        evectionDetailType: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            popularList: [
                {
                    id: 1,
                    name: '符合差标',
                    isSelected: false
                },
                {
                    id: 2,
                    name: '协议酒店',
                    isSelected: false
                },
                {
                    id: 3,
                    name: '含早餐',
                    isSelected: false
                },
            ],
            differenceEnable: 0, // 是否符合差标
            agreementEnable: 0, // 是否协议酒店
            isBreakfast: 0, // 是否含早
            filterList: [
                {
                    name: '1',
                    label: '位置区域',
                    height: 26,
                    isSelected: false
                },
                {
                    name: '2',
                    label: '星级/价格',
                    height: 26,
                    isSelected: false
                },
                {
                    name: '3',
                    label: '筛选',
                    height: 26,
                    isSelected: false
                }
            ],//筛选的第一层分类列表
            filterActive: '1',//选中的筛选第一层分类对应的name

            selectedArea: {},//已选区域位置的坐标
            districtActive: '',//已选的行政区筛选条件

            starList: [
                {
                    id: '2',
                    name: '≤二星/经济',
                    isSelected: false
                },
                {
                    id: '3',
                    name: '三星/舒适型',
                    isSelected: false
                },
                {
                    id: '4',
                    name: '四星/高档型',
                    isSelected: false
                },
                {
                    id: '5',
                    name: '五星/豪华型',
                    isSelected: false
                },
            ],//星级list
            starValues: [],//已选的星级idlist
            selectedStarList: [],//已选的星级对象list

            minValue: '',//最低价
            maxValue: '',//最高价
            selectedPrice: '',//已填的价格范围提示标签
            isAvailablePrice: true,//价格范围是否有效

            brandClassifiedList: [
                {
                    id: 1,
                    name: '快捷连锁',
                    isSelected: true
                },
                {
                    id: 2,
                    name: '中端连锁',
                    isSelected: false
                },
                {
                    id: 3,
                    name: '高端连锁',
                    isSelected: false
                },
                {
                    id: 4,
                    name: '其他品牌',
                    isSelected: false
                },
            ],//品牌连锁分类list
            showBrandPopperActive: '',//当前需要显示的品牌连锁弹框type
            currBrandList: [],//当前显示的品牌连锁list
            selectedBrandList: [],//已选的品牌连锁list

            sortActive: 1,//使用排序条件对应的条件id
            sortList: [
                {
                    id: 1,
                    code: 0,
                    name: '推荐排序',
                    isSelected: true
                },
                {
                    id: 2,
                    code: 2,
                    name: '价格低到高',
                    isSelected: false
                },
                {
                    id: 3,
                    code: 3,
                    name: '价格高到低',
                    isSelected: false
                },
                {
                    id: 4,
                    code: 1,
                    name: '距离近到远',
                    isSelected: false
                },
            ],//排序条件list
            sort: 0,
            priceSortActive: 0, //价格排序

            allFilterList: [],
            activeFilter: {},
            activeFilterDetail: {},
            hotelBrandList: [],
            brandDialogVisible: false,
            currentFilterItem: {},
            selectedBrand: false, // 展示已选筛选项
        };
    },
    components: {
        HotelAreaSelector
    },
    computed: {

    },
    methods: {
        //初始化
        async init() {
            //初始化filter组件
            this.initFilters();
            //获取allFilterlist
            await this.getHotelBrandList();
            return this.activeFilter;
        },

        //初始化filter组件
        initFilters() {
            //初始化位置区域选择组件
            this.$refs.hotelAreaSelector.init({
                cityId: this.cityId, // 城市id
            })
        },

        setValue(params) {
            let sortMenuItem = {};

            /*设置推荐排序*/
            this.sort = params.sort || 0;
            this.sortList.forEach(item => {
                item.isSelected = false;
                if (item.code === this.sort) {
                    item.isSelected = true;
                }
            })
        },

        //清空已选位置区域
        clearSelectedArea() {
            this.selectedArea = {};
            this.$refs.hotelAreaSelector.clearActiveItem();
        },

        //使用排序条件进行排序
        onSortClick(item) {
            this.sortList.forEach(item => {
                item.isSelected = false;
            })
            item.isSelected = true;
            this.sort = item.code;
            this.changeEmit('recommend');
        },

        //发布更改筛选条件
        changeEmit(str) {
            //区域位置筛选条件
            let areaInfo = this.selectedArea;
            let areaId = '';
            if (areaInfo.type === '002') {
                areaId = areaInfo.districtId;
            }

            //星级筛选条件
            const hotelStar = this.starValues.join(',');

            //价格筛选条件
            let minPrice = '';
            let maxPrice = '';
            if (this.minValue || this.maxValue) {
                minPrice = this.minValue ? Number(this.minValue) : '0';
                maxPrice = this.maxValue ? Number(this.maxValue) : '100000';
            }
            if (minPrice > maxPrice) {
                this.isAvailablePrice = false;
                return;
            }
            this.isAvailablePrice = true;

            //坐标筛选条件
            let pt = '';
            if (this.selectedArea.latitude && this.selectedArea.longitude) {
                pt = [this.selectedArea.latitude, this.selectedArea.longitude].join(',');
            }

            const isNear = this.sortActive === 2 ? 1 : '';

            let value = {};
            console.log('areaInfo', areaInfo)
            switch (str) {
                case 'recommend':
                    value = {
                        sort: this.sort,
                    };
                    break;
                case 'area':
                    value = {
                        areaId: areaId,
                        areaType: areaInfo.label ? areaInfo.type : '001',
                        areaName: areaInfo.label ? areaInfo.label : '',//已选的区域位置名字
                        lineCode: areaInfo.type === '003' ? areaInfo.lineCode : '',
                        stationValue: areaInfo.type === '004' ? areaInfo.firstValue : '',
                        // d: this.selectedDistance,
                        pt: areaInfo.longitude && areaInfo.latitude ? `${areaInfo.latitude},${areaInfo.longitude}` : '',// 经度，纬度
                        pt_from_type: '200'
                    };
                    if (areaInfo.label) {
                        this.$set(value, 'filterType', 0)
                    }
                    break;
                case 'star':
                    value = {
                        hotelStar
                    };
                    break;
                case 'price':
                    value = {
                        minPrice: minPrice,
                        maxPrice: maxPrice,
                    };
                    break;
                case 'filter':
                    value = {
                        activeFilter: this.activeFilter,//品牌连锁筛选条件
                    };
                    break;
                case 'popular':
                    value = {
                        differenceEnable: this.differenceEnable,//是否符合差标
                        agreementEnable: this.agreementEnable,//是否是协议酒店
                        isBreakfast: this.isBreakfast, // 是否含早餐
                    };
                    break;
                case 'clear':
                    value = {
                        sort: '0',
                        areaId: '',
                        areaType: '001',
                        areaName: '',
                        lineCode: '',
                        stationValue: '',
                        pt: '',
                        pt_from_type: '000',
                        hotelStar: '',
                        minPrice: '0',
                        maxPrice: '100000',
                        activeFilter: this.activeFilter,
                        differenceEnable: this.differenceEnable,//是否符合差标
                        agreementEnable: this.agreementEnable,//是否是协议酒店
                        isBreakfast: this.isBreakfast,
                    };
                    break;
            }
            this.$emit('on-change', value);
        },

        //修改热门筛选
        onChangePopular(item) {
            item.isSelected = !item.isSelected;
            switch (item.id) {
                case 1:
                    this.differenceEnable = item.isSelected ? 1 : 0;
                    break;
                case 2:
                    this.agreementEnable = item.isSelected ? 1 : 0;
                    break;
                case 3:
                    this.isBreakfast = item.isSelected ? 1 : 0;
                    break;
            }
            this.changeEmit('popular');
        },

        //改变价格范围时
        onPriceRangeBlur() {
            let minPrice = '';
            let maxPrice = '';
            minPrice = this.minValue ? Number(this.minValue) : '';
            maxPrice = this.maxValue ? Number(this.maxValue) : '';

            //检查最低价和最高价是否合法
            if (this.minValue && this.maxValue) {
                if (minPrice > maxPrice) {
                    this.isAvailablePrice = false;
                    return;
                }
            }
            this.isAvailablePrice = true;

            //判断当前是否使用价格筛选
            if (this.minValue || this.maxValue) {
                this.filterList[1].isSelected = true;
            } else {
                this.filterList[1].isSelected = false;
            }

            //生成价格筛选提示标签
            let price = '';
            this.selectedPrice = '';
            if (minPrice && !maxPrice) {
                price = '≥￥' + minPrice;
            } else if (!minPrice && maxPrice) {
                price = '≤￥' + maxPrice;
            } else if (minPrice && maxPrice) {
                price = '￥' + minPrice + '-' + maxPrice;
            }
            this.selectedPrice = price;

            this.changeEmit('price');
        },

        //改变选中的位置区域
        changeArea(val) {
            this.districtActive = '';
            if (val.type === '002') {
                this.districtActive = val.districtId;
            }
            this.selectedArea = val;
            this.filterList[0].isSelected = true;
            setTimeout(() => {
                this.changeEmit('area');
            }, 500)
        },

        //选择星级
        onChooseStar(item) {
            let index = this.starValues.indexOf(item.id);
            if (index === -1) {
                this.starValues.push(item.id);
                this.selectedStarList.push(item);
                item.isSelected = true;
            } else {
                this.starValues.splice(index, 1);
                this.selectedStarList.splice(index, 1);
                item.isSelected = false;
            }
            if (this.starValues.length === 0) {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
        },

        //获取品牌连锁列表
        async getHotelBrandList() {
            let [err, res] = await awaitWrap(Promise.all([consumer_hotel_search_filterItem({'cityId': this.cityId}), consumer_hotel_common_cityBrand({'cityId': this.cityId})]))
            if (err) {
                return
            }
            let filterList = res[0].datas;
            let activeFilter = {};
            filterList.forEach(value=>{
                console.log(value);
                if (value.radio === true) {
                    activeFilter[value.key] = '';
                } else {
                    activeFilter[value.key] = [];
                }
            })
            this.allFilterList = filterList;
            this.activeFilter = activeFilter;
            this.activeFilterDetail = JSON.parse(JSON.stringify(activeFilter));
            this.hotelBrandList = res[1].datas;

            /*let res = await consumer_hotel_search_getHotelBrandList();
            this.brandList = res.datas;
            this.currBrandList = this.brandList.fresult;*/
        },

        //选中品牌连锁分类
        choseBrandClassified(classifiedItem) {
            this.brandClassifiedList.forEach(item => {
                this.$set(item, 'isSelected', false)
                if (item.id === classifiedItem.id) {
                    this.$set(item, 'isSelected', true)
                }
            });
            switch (classifiedItem.id) {
                case 1:
                    this.currBrandList = this.brandList.fresult;
                    break;
                case 2:
                    this.currBrandList = this.brandList.sresult;
                    break;
                case 3:
                    this.currBrandList = this.brandList.tresult;
                    break;
                case 4:
                    this.currBrandList = this.brandList.oresult;
                    break;
            }
        },

        //显示品牌连锁弹框
        showPopper(val) {
            this.showBrandPopperActive = val.key
        },

        //隐藏品牌连锁弹框
        hidePopper() {
            this.showBrandPopperActive = ''
        },

        //删除已选位置区域
        delSelectedArea() {
            this.selectedArea = {};
            this.districtActive = '';//已选的行政区筛选条件
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList[0].isSelected = false;
            this.changeEmit('area');
        },

        //删除已选位置区域
        delSelectedAreaNoEmit() {
            this.selectedArea = {};
            this.districtActive = '';//已选的行政区筛选条件
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList[0].isSelected = false;
        },

        //删除已选星级
        delSelectedStar(item) {
            let index = this.starValues.indexOf(item.id);
            this.starValues.splice(index, 1);
            this.selectedStarList.splice(index, 1);
            item.isSelected = false;
            if (this.starValues.length === 0 && this.selectedPrice === '') {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
        },

        //删除已填的价格范围
        delSelectedPrice() {
            this.minValue = '';
            this.maxValue = '';
            this.selectedPrice = '';
            this.isAvailablePrice = true;
            if (this.starValues.length === 0 && this.selectedPrice === '') {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
            this.changeEmit('price');
        },

        //删除已选的品牌范围
        delSelectedBrandForList(item, key, currentIndex) {
            this.activeFilter[key].splice(currentIndex, 1);
            this.activeFilterDetail[key].splice(currentIndex, 1);
            this._filterIsSelect();
        },

        delSelectedBrand(item, key) {
            this.activeFilter[key] = '';
            this.activeFilterDetail[key] = '';
            this._filterIsSelect();
        },

        //清空所有已选筛选条件
        clearAll() {
            this.selectedArea = {};//已选区域位置的坐标
            this.districtActive = '';//已选的行政区筛选条件
            this.starValues = [];//已选的星级idlist
            this.selectedStarList = [];//已选的星级对象list
            this.minValue = '';//最低价
            this.maxValue = '';//最高价
            this.selectedPrice = '';//已填的价格范围提示标签
            this.isAvailablePrice = true;//价格范围是否有效
            this.selectedBrandList = [];//已选的品牌连锁list
            let activeFilter = {};
            this.allFilterList.forEach(value=>{
                console.log(value);
                if (value.radio === true) {
                    activeFilter[value.key] = '';
                } else {
                    activeFilter[value.key] = [];
                }
            })
            this.activeFilter = activeFilter;
            this.activeFilterDetail = JSON.parse(JSON.stringify(activeFilter));
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList.forEach((item) => {
                item.isSelected = false;
            })
            this.starList.forEach((item) => {
                item.isSelected = false;
            })
            this.selectedBrand = false;
            this.changeEmit('clear');
        },

        _isActive(activeItem, item) {
            let active = false;
            if (item.radio === true) {
                if (this.activeFilter[item.key] === activeItem.key) {
                    active = true;
                }
            }
            if (item.radio === false) {
                if (this.activeFilter[item.key].indexOf(activeItem.key) > -1) {
                    active = true;
                }
            }
            return active;
        },
        _setActive(activeItem, item) {
            if (item.radio === true) {
                if (this.activeFilter[item.key] === activeItem.key) {
                    this.activeFilter[item.key] = '';
                    this.activeFilterDetail[item.key] = '';
                } else {
                    this.activeFilter[item.key] = activeItem.key;
                    this.activeFilterDetail[item.key] = activeItem;
                }
            }
            if (item.radio === false) {
                let index = this.activeFilter[item.key].indexOf(activeItem.key);
                if (index > -1) {
                    this.activeFilter[item.key].splice(index, 1);
                    this.activeFilterDetail[item.key].splice(index, 1);
                } else {
                    this.activeFilter[item.key].push(activeItem.key);
                    this.activeFilterDetail[item.key].push(activeItem);
                }
            }
            this._filterIsSelect();
        },
        _showALlBrand(item) {
            this.currentFilterItem = item;
            this.brandDialogVisible = true;
        },
        _setActiveForAll(activeItem, item, activeIndex) {
            const activeAllId = (activeIndex + 1).toString();
            if (this.currentFilterItem.radio === true) {
                if (this.activeFilter[this.currentFilterItem.key] === activeItem.id) {
                    this.activeFilter[this.currentFilterItem.key] = '';
                    this.activeFilterDetail[this.currentFilterItem.key] = '';
                } else {
                    this.activeFilter[this.currentFilterItem.key] = activeItem.id;
                    activeItem.key = activeItem.id;
                    activeItem.label = activeItem.brandName;
                    this.activeFilterDetail[this.currentFilterItem.key] = activeItem;
                }
            }
            if (this.currentFilterItem.radio === false) {
                let index = this.activeFilter[this.currentFilterItem.key].indexOf(activeItem.id);
                if (index > -1) {
                    this.activeFilter[this.currentFilterItem.key].splice(index, 1);
                    this.activeFilterDetail[this.currentFilterItem.key].splice(index, 1);
                } else {
                    this.activeFilter[this.currentFilterItem.key].push(activeItem.id);
                    activeItem.key = activeItem.id;
                    activeItem.label = activeItem.brandName;
                    this.activeFilterDetail[this.currentFilterItem.key].push(activeItem);
                    const aIndex = this.activeFilter[this.currentFilterItem.key].indexOf(activeAllId);
                    if (aIndex > -1) {
                        this.activeFilter[this.currentFilterItem.key].splice(aIndex, 1);
                        this.activeFilterDetail[this.currentFilterItem.key].splice(aIndex, 1);
                    }
                }
            }
            this._filterIsSelect();
        },
        _isActiveForAll(activeItem, item) {
            let active = false;
            if (this.currentFilterItem.radio === true) {
                if (this.activeFilter[this.currentFilterItem.key] === activeItem.id) {
                    active = true;
                }
            }
            if (this.currentFilterItem.radio === false) {
                if (this.activeFilter[this.currentFilterItem.key].indexOf(activeItem.id) > -1) {
                    active = true;
                }
            }
            return active;
        },
        _setAll(activeIndex, item) {
            const activeAllId = (activeIndex + 1).toString();
            // 单选
            if (this.currentFilterItem.radio === true) {
                if (this.activeFilter[this.currentFilterItem.key] === activeAllId) {
                    this.activeFilter[this.currentFilterItem.key] = '';
                    this.activeFilterDetail[this.currentFilterItem.key] = '';
                } else {
                    this.activeFilter[this.currentFilterItem.key] = activeAllId;
                    this.activeFilterDetail[this.currentFilterItem.key] = {key: activeAllId, label: item.label + '全部'};
                }
            }
            // 多选
            if (this.currentFilterItem.radio === false) {
                let cFilterList = this.activeFilter[this.currentFilterItem.key];
                let cFilterDetailList = this.activeFilterDetail[this.currentFilterItem.key];
                let newFilterList = [];
                let newFilterDetailList = [];
                let list = item['list'] || [];
                const cIndex = cFilterList.indexOf(activeAllId);
                newFilterDetailList.push({key: activeAllId, label: item.label + '全部'})
                if (cIndex === -1) {
                    newFilterList.push(activeAllId);
                    cFilterList.forEach((element,eIndex)=>{
                        let hasId = false;
                        list.forEach(element2=>{
                            if (element2['id'] === element) {
                                hasId = true;
                            }
                        })
                        if (hasId === false) {
                            newFilterList.push(element);
                            newFilterDetailList.push(cFilterDetailList[eIndex]);
                        }
                    })
                    this.activeFilter[this.currentFilterItem.key] = newFilterList;
                    this.activeFilterDetail[this.currentFilterItem.key] = newFilterDetailList;
                } else {
                    cFilterList.splice(cIndex, 1);
                    cFilterDetailList.splice(cIndex, 1);
                    this.activeFilter[this.currentFilterItem.key] = cFilterList;
                    this.activeFilterDetail[this.currentFilterItem.key] = cFilterDetailList;
                }
            }
            this._filterIsSelect();
        },
        _filterIsSelect() {
            let select = false;
            this.allFilterList.forEach(value => {
                console.log(this.currentFilterItem[value.key]);
                if (value.radio === true && this.activeFilter[value.key] !== '') {
                    select = true;
                }
                if (value.radio === false && this.activeFilter[value.key].length !== 0) {
                    select = true;
                }
            })
            this.filterList[2].isSelected = select;
            this.selectedBrand = select;
            this.changeEmit('filter');
        },
        _isBar(item) {
            console.log(item)
            let select = false;
            if (item.radio === true) {
                if (this.activeFilter[item.key] !== '') {
                    select = true;
                }
            }
            if (item.radio === false) {
                if (this.activeFilter[item.key].length !== 0) {
                    select = true;
                }
            }
            return select;
        }
    },
    watch: {
        //监测城市的变化
        cityId() {
            //this.initFilters();
            //this.getHotelBrandList();
        },

        //监测已选位置区域
        selectedArea(newVal, oldVal) {
            if (!oldVal.type && newVal.type) {
                this.$emit('on-area-change');
            }
        },

        //监测已选星级
        starValues() {
            this.changeEmit('star');
        },

        //监测已选品牌连锁
        selectedBrandList() {
            if (this.selectedBrandList.length === 0) {
                this.filterList[2].isSelected = false;
            } else {
                this.filterList[2].isSelected = true;
            }
            this.changeEmit('brand');
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        })
    }
}
