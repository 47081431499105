// 最新审批流
import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/approve-read-detail-for-workflow',
            name: 'admin-approve-read-detail-for-workflow',
            component: () => import(/* webpackChunkName: "page-admin-office-approve-read-detail-for-workflow" */ `@/page/admin/office/approve-detail-for-workflow/index.vue`),
            meta: {
                lastMenu: 'adminHaveApprove'
            },
        }
    ],
};
