import _ from "underscore";
import consumer_flight_search_citys from "@/lib/data-service/haolv-default/consumer_flight_search_citys";
import consumer_flight_search_foreignCitys from '@/lib/data-service/haolv-default/consumer_flight_search_foreignCitys';
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import to_string from "nllib/src/convert/to_string";
import check_is_contains_chinese_characters from "nllib/src/common/check_is_contains_chinese_characters";
import extend from "extend";
export default {
    data() {
        return {
            // val: null, // 选中的对象
            inputKey: '',
            show: false,
            showType: '1', // 1: 正常，2:搜索
            leftActiveName: '1',
            topActiveName: '1',
            topActiveName2: '1',

            flagMouseOverPanel: false,

            allCityList: [], //国内所有城市机场
            //hotList: [], // 国内热门机场
            resultList: [], // 国内展示用城市列表

            iAllCityList: [], //国际所有城市机场
            //iHotList: [], // 国际热门机场
            iResultList: [], // 国际展示用城市列表


            filterList: [], // 筛选列表
        }
    },
    props: {
        value: {
            // type: Object,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this._getFlightCity();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        value(newVal) {
            this.inputKey = newVal ? newVal.name_custom_001 : '';
        },
    },
    computed: {},
    filters: {},
    methods: {
        clickReplace(item) {
            this.value = item;
        },
        _handleClick() {
            this.show = true;
            this.showType = '1';
            this.leftActiveName = '1';
            this.topActiveName = '1';
            this.$refs.aInput.select();
            this.$nextTick(()=>{

            })
        },
        _handleBlur() {
            if (this.inputKey == '' || this.inputKey == null) {
                this.$emit('changeFun', null);
            }
            if (!this.flagMouseOverPanel) {
                this.show = false;
            }
        },
        _handleKeyup($event) {
            console.log($event, this.inputKey);
            const code = $event.code;

            if (_.contains([`ArrowDown`, `ArrowUp`, `ArrowLeft`, `ArrowRight`, `Enter`, `NumpadEnter`, `Tab`], code)) {
                return;
            }
            //不是特殊按键，进行初始化数据源
            //特殊按键：向下箭头、向上箭头，回车
            //默认选中第一个
            if (this.inputKey) {
                this.showType = '2';
                let allCityList = [...this.allCityList, ...this.iAllCityList];
                const _input = (this.inputKey || '').toLowerCase();
                this.filterList = _.chain(allCityList).filter((m) => {
                    console.log(m);
                    const code = to_string(m['code']).toLowerCase();
                    const name = to_string(m['name']).toLowerCase();
                    const pin_yin = to_string(m['spell']).toLowerCase();
                    const jian_pin = to_string(m['spell']).toLowerCase();
                    const extend_001 = to_string(m['cityCode']).toLowerCase();
                    const extend_002 = to_string(m['cityName']).toLowerCase();

                    let flag = false;
                    flag = flag || (code.indexOf(_input) > -1);
                    flag = flag || (name.indexOf(_input) > -1);
                    flag = flag || (pin_yin.indexOf(_input) > -1);
                    flag = flag || (jian_pin.indexOf(_input) > -1);
                    flag = flag || (extend_001.indexOf(_input) > -1);
                    flag = flag || (extend_002.indexOf(_input) > -1);
                    if (flag) {
                        console.log(m);
                    }
                    return flag;
                }).map((m) =>{
                    //由于改变了数组的费用，必须进行深拷贝，否则vue的双向绑定无效！
                    const o = extend(true, {}, m);
                    return o;
                }).value()
            } else {
                this.showType = '1';
            }


        },
        _handleFocus() {
            this.show = true;
        },
        _handleToFocus() {
            console.log('11focus');
            this.$refs.aInput.focus();
        },
        _handleMouseenter() {
            this.flagMouseOverPanel = true
        },
        _handleMouseleave() {
            this.flagMouseOverPanel = false;
        },
        async _getFlightCity() {
            let [err, res] = await awaitWrap(consumer_flight_search_citys());
            if (err) {
                return;
            }
            this.allCityList = res.citys || [];
            this.allCityList.forEach(value => {
                value.areaType = 1;
            })

            let [iErr, iRes] = await awaitWrap(consumer_flight_search_foreignCitys());
            if (iErr) {
                return
            }
            this.iAllCityList =  iRes.citys || [];
            this.iAllCityList.forEach(value => {
                value.areaType = 2;
            })

            // 开始过滤
            /*this.hotList = _.chain(this.allCityList).filter( (m) => {
                return 1 === 1 && m.isHot === `Y` && !m.airports;
            }).value();

            const firstLetterList = _.chain(this.allCityList).filter( (m) => {
                return 1 === 1 && !m.airports;
            }).groupBy( (m) => {
                return m.initial;
            }).map(function (property_value, property_name) {
                    const first_letter = property_name;
                    const entity_list = property_value;
                    const o = {};
                    o.firstLetter = first_letter;
                    o.entityList = entity_list;
                    return o;
                }).value();
            const tabList000 = [
                {
                    firstLetterList: [
                        {firstLetter: `A`},
                        {firstLetter: `B`},
                        {firstLetter: `C`},
                        {firstLetter: `D`},
                        {firstLetter: `E`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `F`},
                        {firstLetter: `G`},
                        {firstLetter: `H`},
                        {firstLetter: `I`},
                        {firstLetter: `J`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `K`},
                        {firstLetter: `L`},
                        {firstLetter: `M`},
                        {firstLetter: `N`},
                        {firstLetter: `O`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `P`},
                        {firstLetter: `Q`},
                        {firstLetter: `R`},
                        {firstLetter: `S`},
                        {firstLetter: `T`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `U`},
                        {firstLetter: `V`},
                        {firstLetter: `W`},
                        {firstLetter: `X`},
                        {firstLetter: `Y`},
                        {firstLetter: `Z`},
                    ],
                },
            ];
            const tabList002 = _.chain(tabList000).map((m, i) => {
                const firstLetterList = m.firstLetterList;
                const title = _.chain(firstLetterList).map((n) =>{
                    return n.firstLetter;
                }).value().join(``);

                const o = {};
                o.type = `multipleLetters`;
                o.title = title;
                o.name = `${i+2}`;
                o.firstLetterList = firstLetterList;
                return o;
                }).value();

            let tabList003 = [];
            tabList003.push({
                type: `hotEntity`,
                title: `热门`,
                name: '1',
                hotEntityList: JSON.parse(JSON.stringify(this.hotList)),
            });
            tabList003 = tabList003.concat(tabList002);

            const allFirstLetterList = _.chain(tabList002).map((m) => {
                return m.firstLetterList;
            }).flatten().map((n) =>{
                const firstLetter = n.firstLetter;

                let entityList = [];
                const query = _.chain(firstLetterList).find((o) => {
                    return o.firstLetter === firstLetter;
                }).value();
                if (query) {
                    entityList = query.entityList;
                }

                n.entityList = entityList;
                return n;
            }).value();
            this.resultList = tabList003;*/
            this.resultList = this.setCityShowList(this.allCityList);
            this.iResultList = this.setCityShowList(this.iAllCityList);
            console.log(this.resultList);
            console.log(this.iResultList);
        },
        aa() {},
        // 封装展示用城市数组
        setCityShowList(allCityList) {
            let hotList = [];
            hotList = _.chain(allCityList).filter( (m) => {
                return 1 === 1 && m.isHot === `Y` && !m.airports;
            }).value();

            const firstLetterList = _.chain(allCityList).filter( (m) => {
                return 1 === 1 && !m.airports;
            }).groupBy( (m) => {
                return m.initial;
            }).map(function (property_value, property_name) {
                const first_letter = property_name;
                const entity_list = property_value;
                const o = {};
                o.firstLetter = first_letter;
                o.entityList = entity_list;
                return o;
            }).value();
            const tabList000 = [
                {
                    firstLetterList: [
                        {firstLetter: `A`},
                        {firstLetter: `B`},
                        {firstLetter: `C`},
                        {firstLetter: `D`},
                        {firstLetter: `E`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `F`},
                        {firstLetter: `G`},
                        {firstLetter: `H`},
                        {firstLetter: `I`},
                        {firstLetter: `J`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `K`},
                        {firstLetter: `L`},
                        {firstLetter: `M`},
                        {firstLetter: `N`},
                        {firstLetter: `O`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `P`},
                        {firstLetter: `Q`},
                        {firstLetter: `R`},
                        {firstLetter: `S`},
                        {firstLetter: `T`},
                    ],
                },
                {
                    firstLetterList: [
                        {firstLetter: `U`},
                        {firstLetter: `V`},
                        {firstLetter: `W`},
                        {firstLetter: `X`},
                        {firstLetter: `Y`},
                        {firstLetter: `Z`},
                    ],
                },
            ];
            const tabList002 = _.chain(tabList000).map((m, i) => {
                const firstLetterList = m.firstLetterList;
                const title = _.chain(firstLetterList).map((n) =>{
                    return n.firstLetter;
                }).value().join(``);

                const o = {};
                o.type = `multipleLetters`;
                o.title = title;
                o.name = `${i+2}`;
                o.firstLetterList = firstLetterList;
                return o;
            }).value();

            let tabList003 = [];
            tabList003.push({
                type: `hotEntity`,
                title: `热门`,
                name: '1',
                hotEntityList: JSON.parse(JSON.stringify(hotList)),
            });
            tabList003 = tabList003.concat(tabList002);

            const allFirstLetterList = _.chain(tabList002).map((m) => {
                return m.firstLetterList;
            }).flatten().map((n) =>{
                const firstLetter = n.firstLetter;

                let entityList = [];
                const query = _.chain(firstLetterList).find((o) => {
                    return o.firstLetter === firstLetter;
                }).value();
                if (query) {
                    entityList = query.entityList;
                }

                n.entityList = entityList;
                return n;
            }).value();
            return tabList003;
        },
        _handleEnter(val) {
            this.inputKey = `${val.name}(${val.code})`;
            this.$emit('input', val);
            setTimeout(()=>{
                this.flagMouseOverPanel = false;
                this.show = false;
            }, 100)
        },
    },

}