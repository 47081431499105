import consumer_apply_newWorkflowApproveList from '@/lib/data-service/haolv-default/consumer_apply_newWorkflowApproveList';
import moment from "moment";
export default {
    data() {
        return {
            jurisdiction: true,
            loading: false,
            form: {
                "searchValue": "",
                "approveType": 0,
                "currentPage": 1,
                "pageSize": 9,
                "startDate": "",
                "status": 0,
                date: [],
                total: 0,
            },
            options: [
                // 审批类型：1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请， 6.授信申请， 7.预订申请
                {
                    value: 0,
                    label: "全部",
                },
                {
                    value: 1,
                    label: "出差申请",
                },
                {
                    value: 2,
                    label: "超标申请",
                },
                {
                    value: 7,
                    label: "预订申请",
                },
                {
                    value: 4,
                    label: "改签申请",
                },
                {
                    value: 5,
                    label: "退订申请",
                },
            ],
            list: [],


        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
    },
    async activated() {
        await this.$store.state.workflowDefer.promise;
        console.log(this.$route);
        if (this.$route.name === 'admin-my-apply-manage-no-approve') {
            // 待我审批
            this.form.status = 1;
        } else {
            // 我已审批
            this.form.status = 2;
        }
        this._getList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {

        _search() {
            this.form.currentPage = 1;
            this._getList();
        },
        _changeDate(val) {
            if (val) {
                this.form.startDate = val[0] + ' 00:00:00';
                this.form.endDate = val[1] + ' 23:59:59';
            } else {
                this.form.startDate = '';
                this.form.endDate = '';
            }
        },
        _getList() {
            this.loading = true;
            consumer_apply_newWorkflowApproveList(this.form).then(res=>{
                let list = res.datas.list;
                list.forEach(value=>{
                    value.approveTypeSmallText = value.approveTypeText.substring(0, 2);
                    if (value.approveType === 1) {
                        console.log(value.endDate, moment.startDate, moment(value.endDate).diff(moment.startDate, 'day'));
                        value.day = moment(value.endDate).diff(moment(value.startDate), 'day') + 1;

                    }
                    if ([2,7,4,5,8,9].indexOf(value.approveType) > -1) {
                        let departDay = moment(value.departTime).format('yyyy-MM-DD');
                        let returnDay = moment(value.returnTime).format('yyyy-MM-DD');
                        value.between = moment(returnDay).diff(moment(departDay), 'day');
                    }
                })
                this.list = list;
                this.form.total = res.datas.totalCount;
            }).finally(()=>{
                this.loading = false;
            })
        },

        _setLabel(val) {
            // 审批类型：1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请，6.授信申请，7.预订申请，8.预订-改签申请，9.预订-退票申请
            let text = '';
            switch (val.approveType) {
                case 1:
                    text = 'evection';
                    break
                case 2:
                    text = 'overproof';
                    break
                case 7:
                    text = 'approvebooking';
                    break
                case 4:
                    text = 'changing';
                    break
                case 8:
                    text = 'changing';
                    break
                case 5:
                    text = 'unsubscribe';
                    break
                case 9:
                    text = 'unsubscribe';
                    break
            }
            return text;
        },
        _setStatus(val) {
            // 审批单状态 1.审核中，2.已撤销，3.驳回，4.审核拒绝，5.审核通过，6.委派，7.转办'
            let text = '';
            switch (val.approveStatus) {
                case 1:
                    text = 'approvedByMe';
                    break
                case 2:
                    text = 'invalid';
                    break
                case 3:
                    text = 'reject';
                    break
                case 4:
                    text = 'reject';
                    break
                case 5:
                    text = 'adopt';
                    break
                case 6:
                    text = 'approvedByMe';
                    break
                case 7:
                    text = 'approvedByMe';
                    break
            }
            return text;
        },
        _handleCurrentChange(currPage) {
            this.form.currentPage = currPage;
            this._getList();
        },
        _toDetail(val) {
            let _routeName = '';
            if (this.form.status === 1) {
                _routeName = 'admin-approve-detail-for-workflow';
            } else {
                _routeName = 'admin-approve-read-detail-for-workflow';
            }
            this.$router.push({
                name: _routeName,
                query: {
                    applyNo: val.applyNo,
                    approveType: val.approveType,
                    applyType: 2, // 1我的申请，2待我审批
                    status: this.form.status,
                    taskId: val.taskId,
                }
            });

        },
        _setTime(val) {
            if (!val) {
                return '';
            }
            return moment(val).format('yyyy-MM-DD HH:mm');
        },
        _setDay(val) {
            if (!val) {
                return '';
            }
            return moment(val).format('yyyy-MM-DD');
        },
        _setOnlyTime(val) {
            if (!val) {
                return '';
            }
            return moment(val).format('HH:mm');
        },
    }
}
