import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue";
import orderDetail from "@/lib/data-service/haolv-default/consumer_air_ticket_orderDetail";
import consumer_air_ticket_orderDetailForOrderCenter from '@/lib/data-service/haolv-default/consumer_air_ticket_orderDetailForOrderCenter'
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
import cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import consumer_air_ticket_returnFligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_returnFligthRules";// 获取退改规则
import orderLog from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/order-log/1.0.0/index.vue'
import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import ApprovalProcessForWorkflow from "@/component/approval-process-for-workflow/1.0.0/index.vue"
import moment from "moment";
import _ from 'underscore';
import ChangeReturnClausePopup from '@/page/admin/travel-book/flight/component/changeReturnClausePopup/1.0.0/index.vue'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import PolicyTypeLabel from '@/page/admin/travel-book/flight/component/policyTypeLabel/index.vue'
import consumer_flight_search_queryPlaneNameByCodes from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import FlightUpLevelInfo from "@/component/flight-up-level-info/index.vue";
export default {
    data() {
        return {
            loading: false,
            type: 'detail',
            detail: {},
            tabList: [
                {value: '1', label: '订单详情'},
                {value: '2', label: '订单日志'},
            ],
            currentTab: '1',

            ruleShow: false,
            ruleLoading: false,
            ruleTextList: [], // 退改规则
            productTag: 0,

            milliseconds: 0,
            paySetIntervalFun: '',
            approvalMethod: 1, // 1.我的申请 2.待我审批
        }
    },
    components: {
        NewChangeClause,
        orderLog,
        ApprovalProcess,
        ChangeReturnClausePopup,
        PolicyTypeLabel,
        ApprovalProcessForWorkflow,
        FlightUpLevelInfo,
    },
    created() {
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        } else {
            this.type = 'detail'
        }
        this.getOrderDetail()
        //this.getOrderLog()
    },
    deactivated() {
        clearInterval(this.paySetIntervalFun)
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
        filterTicketLimitTime(val) {
            let h = 0;
            let m = 0;
            let text = `${h}小时${m}分钟`
            if (val) {
                // 转换为时分
                text = '';
                h = Math.floor(val / 60);
                h = h > 0 ? h : 0;
                m = val % 60;
                if (h > 0) {
                    text = `${h}小时`;
                }
                if (m > 0) {
                    text += `${m}分钟`;
                }
            }
            return text
        }
    },
    methods: {
        async getOrderDetail() {
            let orderNo = this.$route.query.orderNo || "";
            this.loading = true
            let orderDetailFun = this.type === 'detail' ? orderDetail : consumer_air_ticket_orderDetailForOrderCenter
            let res = await orderDetailFun({orderNo})
            this.loading = false
            let data = res.datas
            if (data.theFlightInformationList) {
                data.theFlightInformationList.forEach(value => {
                    value.ruleText = []
                    value.luggage = ''
                    if(value.stopNum>0 && value.stopInfoText){
                       let stopInfos = JSON.parse(value.stopInfoText);
                        for (let i = 0; i < stopInfos.length; i++) {
                            if(i==2){
                                stopInfos[i].depTime = stopInfos[i].arrTime
                            }
                        }
                        value.stopInfoObj = stopInfos;
                    }
                })
            }
            console.log('theFlightInformationList',data.theFlightInformationList)
            data.record = {}
            if (data.recordList) {
                data.recordList.forEach(value => {
                    data.record = value
                })
            }
            this.detail = data;
            this.milliseconds = data.milliseconds || 0;
            if (this.milliseconds > 0) {
                this.paySetIntervalFun = setInterval(() => {
                    this.milliseconds--;
                    if (this.milliseconds <= 0) {
                        clearInterval(this.paySetIntervalFun)
                        this.getOrderDetail()
                    }
                }, 1000)
            }
            console.log('chufa - approvalProcess');
            if (this.$store.state.workflow === '2') {
                this.$refs.approvalProcess.init({
                    status: this.detail.status,
                    statusText: this.detail.statusText,
                    approvalData: this.detail.recordList,
                    approvalReason: this.detail.approvalReason,
                    btnParams: {
                        isShow: false,
                        approvalParams: {}
                    }
                })
            }
            if (this.$store.state.workflow === '1') {
                this.$refs.approvalProcessForWorkflow.init({
                    budgetAmount: this.detail.budgetAmount || 0,
                    taskId: this.detail.applyNo,
                    status: this.detail.status,
                    statusText: this.detail.statusText,
                    approvalData: this.detail.recordList,
                    approvalReason: this.detail.approvalReason,
                    btnParams: {
                        //isShow: this.type === 'approve' ? true : false,
                        //approvalParams: this.approvalParams
                    }
                })
            }
            if (this.detail.applyNo) {
                consumer_air_ticket_ApprovalMethod({applyNo: this.detail.applyNo}).then(res => {
                    this.approvalMethod = res.datas.approvalMethod
                })
            }
            //机型描述
            this.queryPlaneNames();
        },
        showThisRuleList(index, val) {
            this.ruleTextList = []
            this.ruleShow = true
            let data = {};
            if(this.detail.flightType === '1') {
                this.productTag = this.detail.theFlightInformationList[0].productTag
                data = {
                    segmentNos: [
                        this.detail.theFlightInformationList[0].segmentNo,
                    ]
                };
            } else {
                data = {
                    segmentNos: [
                        this.detail.theFlightInformationList[0].segmentNo,
                        this.detail.theFlightInformationList[1].segmentNo,
                    ]
                };
            }
            this.ruleLoading = true
            consumer_air_ticket_returnFligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleTextList = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        getStatusColor(val) {
            //  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消'
            if (val === 11 || val === 12 || val === 13 || val === 21) {
                return 'status2'
            } else if (val === 22) {
                return 'status1'
            } else {
                return 'status3'
            }
        },
        getRecordStatus(val) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else {
                return ''
            }
        },
        getRecordStatusText(val) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '待审核'
            } else if (val === 1) {
                return '待审核'
            } else if (val === 2) {
                return '通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
        recordEnd(val) {
            if (!val) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        goPay() {
            if (this.detail.evectionType === '个人预定') {
                this.$router.push({
                    name: "admin-travel-payment",
                    query: {
                        orderNo: this.detail.orderNo,
                        orderType: "1"
                    }
                });
            } else {
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderNos: this.detail.orderNo,
                        orderType: "1",
                        flightOrderList: this.detail.orderNo,
                    }
                });
            }
        },
        cancel() {
            this.$confirm("确定要取消订单吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    let data = {
                        orderNo: this.detail.orderNo
                    };
                    cancelOrder(data).then(res => {
                        this.$message({
                            type: "success",
                            message: '取消订单成功'
                        });
                        this.$router.go(-1);
                    });
                })
                .catch(() => {});
        },
        goEvectionApplyDetail() {
            if (this.$store.state.workflow === '1') {
                this.$router.push({
                    name: 'admin-approve-detail-for-workflow',
                    query: {
                        applyNo: this.detail.applyNo,
                        approveType: this.detail.approveType,
                        applyType: 1,
                    }
                })
            } else {
                // 1.我的申请 2.待我审批
                switch (this.approvalMethod) {
                    case 1:
                        this.$router.push({
                            name: 'admin-my-apply-manage-apply-info',
                            query: {
                                applyId: parseInt(this.detail.applyId),
                                approveType: this.detail.approveType,
                                type: 'info',
                                lastMenu: 'adminMyApply'
                            }
                        })
                        break
                    case 2:
                        this.$router.push({
                            name: 'admin-my-apply-manage-noApprove-detail',
                            query: {
                                applyId: parseInt(this.detail.applyId),
                                approveType: this.detail.approveType,
                                type: 'audit',
                            }
                        })
                        break
                }
            }


        },
        getPolicyDetail() {
            if (this.$store.state.workflow === '1') {
                this.$router.push({
                    name: 'admin-approve-detail-for-workflow',
                    query: {
                        applyNo: this.detail.applyNo,
                        approveType: 2,
                        applyType: 1,
                    }
                })
            } else {
                // /admin/my-apply-manage/apply/info?applyId=3574&type=info&approveType=2&lastMenu=adminMyApply
                this.$router.push({
                    name: 'admin-my-apply-manage-apply-approve-exceed',
                    query: {
                        applyId: this.detail.applicationid,
                        type: 'applyDetail',
                        lastMenu: 'adminMyApply'
                    }
                })
            }

        },
        toList() {
            if (this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
                this.$router.replace({
                    name: 'admin-order-center-flight-order-list'
                })
            } else {
                this.$router.replace({
                    name: 'admin-my-order-flight-orderList'
                })
            }

        },
        handleCurrentTab(val) {
            this.currentTab = val.value
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    // return consumer_log_hotelList({
                    //     orderNo: this.$route.query.id
                    // }).then(res => {
                    // 组装组件需要的list
                    let res = {
                        datas: [{"operator":"个人测试","operatorTime":"2021-12-06T15:54:09","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:01","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:57","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T15:56:08","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T16:02:58","logSource":"1","logContents":"调用胤之旅接口，取消订单成功"}]
                    }
                    const list = _.chain(res.datas)
                        .map(function (m) {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        }).value()
                    const d = {
                        entityList: list
                    }
                    return d || {}
                    // }).catch(() => {
                    //     return []
                    // }).finally(() => {
                    this.loading = false
                    // })
                }
            })
        },
        /*setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
           const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI']
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30']
            const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },*/
        checkInsuranceDetail(val) {
            this.loading = true
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                    window.open(url)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        clickApplyNo() {
            if (this.detail.applicationid) {

                this.$router.push({
                    name: 'admin-my-apply-manage-haveApprove-detail',
                    query: {
                        id: this.detail.applicationid,
                        approveType: 7,
                        type: 'havaReview',
                    },
                });
            }
        },
        queryPlaneNames(){
            const planeTypes = this.detail.theFlightInformationList.map(e => {
                return e.planeType
            })
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.detail.theFlightInformationList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName? planeName:i.planeType;
                })
                this.$forceUpdate();


            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
    }
}
